@use "/src/styles/_variables" as *;
@use "/src/styles/_function" as *;
@use "/src/styles/_media" as *;

.footer {
    color: $color-1;
    position: relative;
    z-index: 1;

    &__wrapper {
        max-width: rem($container - 128); // padding 64 * 2
        display: flex;
        align-items: flex-start;
        margin: 0 auto;
        padding: rem(24) rem(16) rem(88);
        flex-direction: column;
        border-top: 1px solid rgba($color-1, 0.2);

        @include min-media('t') {
            padding: rem(32) rem(32) rem(24);
        }

        @include min-media('d') {
            flex-direction: row;
            height: rem(88);
            padding: rem(32) 0;
            align-items: center;
        }
    }

    &__controls {
        display: flex;
        align-items: center;
        margin-right: auto;
        font: 400 rem(16)/rem(24) 'Journal Sans New', sans-serif;
        margin: rem(-4) rem(-8) rem(12);
        order: 1;
        width: 100%;

        @include min-media('t') {
            margin-left: 0;
            margin-right: 0;
            margin-bottom: rem(24);
            width: initial;
        }

        @include min-media('d') {
            order: 0;
            margin: 0;
        }
    }

    &__logo {
        display: flex;
        align-items: center;
    }

    &__nav {
        order: 0;
        // margin-bottom: rem(24);
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin: rem(-4) rem(-8) rem(20);

        @include min-media('t') {
            margin-left: 0;
            margin-right: 0;
            margin-bottom: rem(16);
            display: block;
            width: initial;
        }

        @include min-media('d') {
            order: 1;
            margin: 0 auto;
            margin-bottom: 0;
        }
    }

    &__nav,
    &__controls {
        & > * {
            width: calc(50% - #{rem(16)});
            margin: rem(4) rem(8);

            @include min-media('t') {
                width: auto;
                margin: 0;
            }
        }
    }

    &__item {
        font: 700 rem(18)/rem(24) 'Journal Sans New', sans-serif;

        &--active {
            opacity: 0.5;
        }

        @include min-media('t') {
            font-size: rem(17);
         }

        & + & {
            @include min-media('t') {
                margin-left: rem(16);
            }
        }
    }

    &__link {
        & + & {
            @include min-media('t') {
                margin-left: rem(16);
            }
        }
    }

    &__copyright {
        font: 400 rem(16)/rem(24) 'Journal Sans New', sans-serif;
        order: 2;
        display: flex;
        align-items: center;
    }

    &__lang {
        order: 1;

        @include max-media('mMax') {
            margin-bottom: rem(16);
        }

        @include only-media('t', 'dMax') {
            display: none;
            order: none;
        }

        @include min-media('d') {
            margin-right: rem(16);
            display: block;
            order: 2;
        }

        &--in-controls {
            display: none;

            @include only-media('t', 'dMax') {
                display: block;
                margin-right: 0;
                margin-left: rem(16);
            }
        }
    }
}
