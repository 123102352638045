@use "/src/styles/_variables" as *;
@use "/src/styles/_function" as *;
@use "/src/styles/_media" as *;

$parent: "card-service";

.card-service {
    position: relative;
    border-radius: rem(12);
    color: $color-1;
    overflow: hidden;
    width: rem(173);
    min-height: rem(172);
    transform: translateZ(0);
    cursor: pointer;
    display: flex;
    flex-direction: column;

    @include min-media('t') {
        width: 100%;
    }

    @include min-media('md') {
        min-height: rem(238);
    }

    @include min-media('d') {
        width: rem(248);
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($color-7, 0.6);
        z-index: -1;

        @supports (backdrop-filter: blur(4px)) {
            backdrop-filter: blur(14px);
        }
    }

    &__icon {
        width: rem(48);
        height: rem(48);
        border-radius: 50%;
        position: relative;
        z-index: 1;
        fill: $color-1;
        stroke: $color-1;

        @include min-media('d') {
            width: rem(64);
            height: rem(64);
        }
    }

    &__text {
        font: 700 rem(18)/rem(24) 'Journal Sans New', sans-serif;
        position: relative;
        display: block;
        margin-top: auto;
        z-index: 1;

        @include min-media("t") {
            max-width: rem(266);
        }

        @include min-media('md') {
            font-size: rem(24);
            line-height: rem(32);
        }
    }

    &__description {
        margin-bottom: auto;

        @include min-media("t") {
          max-width: rem(266);
        }
    }

    &__arrow-icon-wrapper {
        width: rem(32);
        height: rem(32);
        border: 1px solid $color-1;
        border-radius: 50%;
        margin-top: rem(8);
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0.5;
        transition: .3s ease-in-out;
        transition-property: background-color;

        @include min-media(t) {
            width: rem(40);
            height: rem(40);
        }
    }

    &__arrow-icon {
        fill: $color-1;
        transition: .3s ease-in-out fill;
        display: flex;
        width: rem(30);
        height: rem(30);
        transform: scale(80%);

        @include min-media("t") {
            transform: scale(100%);
        }
    }

    @media (hover: hover) {
      &:hover {
        .#{$parent}__arrow-icon-wrapper {
          background-color: $color-1;
          opacity: 1;
        }
    
        .#{$parent}__arrow-icon {
          fill: $color-4;
        }
      }
    }

    &__content {
      position: relative;
      z-index: 2;
      padding: rem(16);
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }

    &__back-image {
      display: none;
    }

    &--style-v2 {
        width: auto;
        height: 100%;
        min-height: rem(460);

        @include min-media("t") {
          min-height: initial;
        }

        .#{$parent} {
            &__icon {
                margin-bottom: rem(16);
            }

            &__text {
                margin-top: 0;
                margin-bottom: rem(8);
                font-size: rem(24);
                line-height: rem(32);
                color: $color-1;
            }

            &__description {
                color: $color-1;
                margin-bottom: rem(8);

                @include min-media("d") {
                  margin-bottom: auto;
                }
            }

            &__back-image {
              display: block;
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              z-index: 1;
        
              img {
                object-fit: cover;
                object-position: top;
                width: 100%;
                height: 100%;
                
                @include min-media("md") {
                  object-position: right;
                }
              }
            }

            &__content {
              padding: rem(24);
              margin-top: auto;
              height: auto;
              flex-grow: 0;
        
              @include min-media("t") {
                padding: rem(24) rem(32);
                height: 100%;
              }
            }
        }
    }

    &--style-v3 {
      width: auto;
      min-height: 0;
      flex-direction: row;

      &:before {
          background-color: #7F4EF71A;

          @supports (backdrop-filter: blur(10px)) {
              backdrop-filter: blur(10px);
          }
      }

      .#{$parent} {
        &__content {
          padding: rem(16);
          flex-direction: row;
          align-items: center;
          gap: rem(16);
  
          @include min-media('d') {
            padding: rem(24);
          }
        }

        &__text {
          margin-top: 0;
        }
      }
    }
}
