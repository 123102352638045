// Breakpoints
$min-media-sizes: (
	"m": 375px,
	"t": 768px,
	'md': 1024px,
	"d": 1280px,
	"xl": 1440px,
	"xxl": 1600px,
	"xxxl": 1920px
);

$max-media-sizes: (
	"sMax": 374px,
	"mMax": 767px,
	"tMax": 1023px,
	"dMax": 1279px,
	"xlMax": 1439px
);

// Sizes
$container: 1280;
$header: 72;
$header_s: 64;

// Colors
$color-1: #fff;
$color-2: #101b37;
$color-3: #081021;
$color-4: #010510;
$color-5: #7f4ef7;
$color-6: #FF5865;
$color-7: #010512;
$color-8: #757B8a;
$color-9: #E3E7EC;
$color-10: #999EAB;
$color-11: #5FB5FC;
$color-12: #C9CAD1;
$color-13: #232530;
$color-14: #976CFF;
$color-15: #A783FF;
$color-16: #ECECEC;
$color-17: #020613;
$color-18: #D9D9D9;


$icon-bg-1: #132a22;
$icon-bg-2: #0c1b30;
$icon-bg-3: #2d1a13;
$icon-bg-4: #321823;

$icon-1: #2FECAC;
$icon-2: #FE5BB4;
$icon-3: #4699E7;

$opacity-color-1: rgba(1, 5, 18, 0.5);
$opacity-color-2: rgba(126, 139, 175, 0.65);
$opacity-color-3: rgba(255, 255, 255, 0.1);
$opacity-color-4: rgba(1, 5, 18, 0);
$opacity-color-5: rgba(1, 5, 18, 0.7);

$gradient-color-1: linear-gradient(180deg, #090E22 0%, #081021 100%);
$gradient-color-2: linear-gradient(180deg, #020615 0%, #01050E 64.93%);

// svg

$arrow-icon: "data:image/svg+xml;charset=UTF-8,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M9.99984 6L8.58984 7.41L13.1698 12L8.58984 16.59L9.99984 18L15.9998 12L9.99984 6Z' fill='white'/%3e%3c/svg%3e ";
$quotes-icon: "data:image/svg+xml;charset=UTF-8,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 17H5L7 13H4V7H10V13L8 17ZM18 17H15L17 13H14V7H20V13L18 17Z' fill='%237F4EF7'/%3e%3c/svg%3e ";
