@use "/src/styles/_variables" as *;
@use "/src/styles/_function" as *;
@use "/src/styles/_media" as *;

$parent: "sharing";

.#{$parent} {
  display: flex;
  column-gap: rem(16);
  row-gap: rem(16);

  &__icon {
    width: rem(48);
    height: rem(48);
    background-color: $color-1;
    border-radius: 50%;
  }
}