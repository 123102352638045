@use "/src/styles/_variables" as *;
@use "/src/styles/_function" as *;
@use "/src/styles/_media" as *;

.filter-tag {
    &__text {
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        height: rem(40);
        padding: rem(8) rem(16);
        border-radius: rem(109);
        background-color: #{$color-1}fff4D;
        color: $color-1;
        cursor: pointer;
        transition: color 0.3s ease, background-color 0.3s ease;
        -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
        &--hover {
            &:hover {
                background-color: $color-1;
                color: $color-4;
            }
        }
    }

    &__input {
        display: none;
    }

    &__input:checked + &__text {
        background-color: $color-1;
        color: $color-4;
    }
    .blogs__filter-tags & &__text {
        padding: rem(8) rem(46);
    }
}