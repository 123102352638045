@use "/src/styles/_variables" as *;
@use "/src/styles/_function" as *;
@use "/src/styles/_media" as *;
@use "/src/styles/_animations" as *;
@use "/src/styles/_grids" as *;

$parent: "running-logos";

.#{$parent} {
    margin-bottom: rem(64);

    @include min-media('d') {
        margin-bottom: rem(120);
    }

    &__head {
        @include min-media('d') {
            padding: 0 rem(62) 0 rem(69);
        }

        &--r-shrink {
            display: block;

            @include min-media('t') {
                display: grid;
                grid-template-columns: minmax(auto, 1.1fr) 1fr;
            }

            @include min-media('d') {
                padding: 0 rem(34) 0 rem(47);
            }
        }
    }

    &__head-wrapper {
        @include min-media('t') {
            padding: 0 rem(32);
        }

        @include min-media('d') {
            padding: 0 rem(64);
        }
    }

    &__items-wrapper {
        @include min-media('d') {
            width: 90%;
            overflow-x: hidden;
            margin: 0 auto;
            -webkit-mask-image: linear-gradient(90deg, transparent 0%, black 40%, black 60%, transparent 100%);
            mask-image: linear-gradient(90deg, transparent 0%, black 40%, black 60%, transparent 100%);
        }
    }

    &__items-scroll {
        display: flex;
        width: fit-content;
    }

    &__items {
        margin-top: rem(16);
        display: flex;
        column-gap: rem(8);
        flex-shrink: 0;
        padding-left: rem(8);

        @include min-media('t') {
            margin-top: rem(40);
            column-gap: rem(24);
            padding-left: rem(24);
        }

        @include min-media('d') {
            margin-top: rem(56);
        }
    }

    &__img-wrapper {
        overflow: hidden;
        position: relative;
    }

    &__img {
        @include min-media('d') {}
    }
}
