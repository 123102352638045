@use "/src/styles/_variables" as *;
@use "/src/styles/_function" as *;
@use "/src/styles/_media" as *;
@use "/src/styles/_animations" as *;

.error-block {
    height: 100vh;

    &__planet {
        position: absolute;
        top: rem(-165);
        left: rem(-68);
        width: rem(170);
        height: rem(170);

        img {
            position: absolute;
            z-index: 2;
            width: 100%;
            height: 100%;
        }

        @include min-media('t') {
            top: rem(38);
            left: rem(30);
            width: rem(170);
            height: rem(170);

            &::after {
                position: absolute;
                top: rem(-360);
                left: rem(-360);
                z-index: 1;
                content: "";
                width: rem(889);
                height: rem(889);
                background: radial-gradient(50% 50% at 50% 50%, rgba(95, 181, 252, 0.82) 0%, rgba(54, 125, 184, 0.42) 20.05%, rgba(3, 4, 10, 0) 100%);
                opacity: 0.7;
            }
        }

        @include min-media('md') {
            top: rem(38);
            left: rem(109);
        }
    }

    &__container {
        z-index: 1;
        position: absolute;
        top: 47%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: rem(341);

        @include min-media('t') {
            top: 39%;
        }
    }

    &__logo-img {
        filter: drop-shadow(0px 0px 14px rgba(255, 255, 255, 0.83));
        padding-bottom: 16px;
    }

    &__number {
        font-size: rem(196);
        line-height: rem(196);
        font-weight: 700;
        color: $color-1;
        text-shadow: 0px 0px 14px rgba(255, 255, 255, 0.83);

        @include min-media('t') {
            margin-bottom: rem(19);
        }
    }

    &__text {
        margin-bottom: rem(24);
        font-size: rem(16);
        line-height: rem(24);
        font-weight: 400;
        color: $color-1;
        text-align: center;
        text-shadow: 0px 0px 14px rgba(255, 255, 255, 0.83);
    }

    &__button {
        z-index: 3;
        width: rem(187);
        height: rem(40);
        filter: drop-shadow(0px 0px 14px rgba(255, 255, 255, 0.83));
    }

    &__spacewalk {
        z-index: 2;
        position: absolute;
        bottom: rem(-187);
        right: rem(185);
        display: none;

        @include min-media(t) {
            display: block;
        }
    }

    &__spaceship {
        z-index: 1;
        position: absolute;
        top: rem(-16);
        left: rem(-26);
    }

    &__hose-container {
        transform-origin: 0 20%;
    }

    &__hose {
        transform-origin: 0 20%;
    }

    &__explosion {
        z-index: 1;
        position: absolute;
        top: rem(134);
        left: rem(330);
        opacity: 0;
    }

    &__part {
        z-index: 1;
        position: absolute;
        top: rem(152);
        left: rem(346);
        width: rem(8);
        height: rem(5);
        background-color: $color-1;
        transform: rotate(45deg);
        opacity: 1;
    }

    &__austronaut {
        z-index: 1;
        position: absolute;
        top: rem(-8);
        right: rem(-138);
        width: rem(236);
        height: rem(236);
        transform-origin: 0 20%;
    }

    &__austronaut-mobile {
        z-index: 3;
        position: absolute;
        bottom: rem(-167);
        right: 0;
        width: auto;
        pointer-events: none;
        animation: levitation 6s ease-in-out infinite;

        @include min-media('t') {
            display: none;
        }
    }

    &__background {
        img {
            display: block;
            width: 100%;
            height: 100vh;
            object-fit: cover;
        }
    }
}