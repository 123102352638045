@use "/src/styles/_function" as *;
@use "/src/styles/_media" as *;
@use "/src/styles/_variables" as *;

.caption-1 {
    font: 700 rem(32)/rem(40) 'Journal Sans New', sans-serif;

    @include min-media('t') {
        font-size: rem(48);
        line-height: rem(56);
    }

    @include min-media('d') {
        font-size: rem(64);
        line-height: rem(72);
    }
}
.caption-2 {
    font: 700 rem(24)/rem(32) 'Journal Sans New', sans-serif;

    @include min-media('t') {
        font-size: rem(48);
        line-height: rem(56);
    }
}
.caption-3 {
    font: 700 rem(24)/rem(32) 'Journal Sans New', sans-serif;

    @include min-media('t') {
        font-size: rem(40);
        line-height: rem(48);
    }
}
.caption-4 {
    font: 700 rem(24)/rem(24) 'Journal Sans New', sans-serif;

    @include min-media('t') {
        font-size: rem(32);
        line-height: rem(40);
    }
}
.caption-5 {
    font: 700 rem(18)/rem(24) 'Journal Sans New', sans-serif;
}
.caption-6 {
    font: 700 rem(14)/rem(24) 'Journal Sans New', sans-serif;
    text-transform: uppercase;

    @include min-media('t') {
        font-size: rem(14);
        line-height: rem(24);
        font-weight: 400;
    }
}
.caption-7 {
    font: 700 rem(32)/rem(40) 'Journal Sans New', sans-serif;

    @include min-media('d') {
        font-size: rem(53);
        line-height: rem(64);
    }
}
.caption-8 {
    font: 400 rem(22)/rem(32) 'Journal Sans New', sans-serif;

    @include min-media('d') {
        font-weight: 700;
        font-size: rem(24);
        line-height: rem(32);
    }
}
.caption-9 {
    font: 700 rem(32)/rem(40) 'Journal Sans New', sans-serif;

    @include min-media('t') {
        font-size: rem(40);
        line-height: rem(48);
    }
}
.caption-10 {
    font: 700 rem(32)/rem(40) 'Journal Sans New', sans-serif;
}

.text-1 {
    font: 400 rem(22)/rem(32) 'Journal Sans New', sans-serif;

    @include min-media('t') {
        font-size: rem(16);
        line-height: rem(24);
    }

    @include min-media('d') {
        font-size: rem(24);
        line-height: rem(32);
    }
}
.text-2 {
    font: 400 rem(16)/rem(24) 'Journal Sans New', sans-serif;
}
.text-3 {
    font: 400 rem(12)/rem(16) 'Journal Sans New', sans-serif;
    // color: $color-8;
}
.text-4 {
    font: 700 rem(18)/rem(24) 'Journal Sans New', sans-serif;

    @include min-media('t') {
        font-size: rem(17);
        line-height: rem(24);
    }
}
.text-5 {
    font: 700 rem(24)/rem(32) 'Journal Sans New', sans-serif;
}

.text-6 {
    font: 400 rem(22)/rem(32) 'Journal Sans New', sans-serif;

    @include min-media('t') {
        font-size: rem(20);
        line-height: rem(26);
    }

    @include min-media('d') {
        font-size: rem(24);
        line-height: rem(32);
    }
}

.text-ln-1 {
    line-height: 1;
}

.container {
    max-width: rem($container);
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: rem(16);
    padding-right: rem(16);

    @include min-media('d') {
        padding-left: 0;
        padding-right: 0;
    }

    &__inner {
        @include max-media('tMax') {
            padding-right: rem(32) !important;
        }

        @include max-media('mMax') {
            padding-right: rem(16) !important;
        }

        @include min-media('d') {
            padding: 0 rem(69) !important;
        }
    }
}

section + section,
.lazyload-wrapper + .lazyload-wrapper,
.lazyload-wrapper + section  {
    margin-top: rem(64);

    @include min-media('t') {
        margin-top: rem(80);
    }

    @include min-media('d') {
        margin-top: rem(60);
    }
}

.lazyload-wrapper {
    height: auto;
}

.gradient-radial {
    min-width: rem(1000);
    min-height: rem(1000);
    border-radius: 50%;
    position: absolute;

    @include min-media('t') {
        min-width: rem(1000);
        min-height: rem(1000);
    }

    @include min-media('d') {
        min-width: rem(1000);
        min-height: rem(1000);
    }

    @include min-media('xl') {
        min-width: rem(1358);
        min-height: rem(1358);
    }

    &--red {
        background-image: radial-gradient(rgba(#FF007A, 0.5) 1%, rgba(#FF007A, 0) 70%);
    }

    &--light-blue {
        background-image: radial-gradient(rgba(#00F0FF, 0.5) 1%, rgba(#00F0FF, 0) 70%);
    }

    &--blue {
        background: radial-gradient(50% 50% at 50% 50%, rgba(#1E73B9, 0.3) 0%, rgba(#1E73B9, 0) 100%);
    }
}
