@use "/src/styles/_variables" as *;
@use "/src/styles/_function" as *;
@use "/src/styles/_media" as *;

$parent: "services-page";

.#{$parent} {
  padding-top: rem(32);

  @include min-media("t") {
    padding-top: rem($header_s + 32);
  }
  
  @include min-media("d") {
    padding-top: rem($header + 88);
  }

  &:after {
    content: '';
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background: radial-gradient(rgba(#900E54, 0.5) 1%, rgba(#900E54, 0) 70%) no-repeat left -20vw top -20vw,
              radial-gradient(rgba(#900E54, 0.5) 1%, rgba(#900E54, 0) 70%) no-repeat right -20vw top -20vw,
              radial-gradient(rgba(#0066FF, 0.5) 1%, rgba(#900E54, 0) 70%) no-repeat bottom -30vw right -20vw;
    background-size: 50vw 50vw, 50vw 50vw, 50vw 50vw;
    background-attachment: fixed;
    pointer-events: none;
    position: fixed;
  }

  &__head {
    display: grid;
    color: $color-1;
    margin-bottom: rem(16);
    
    @include min-media("d") {
      grid-template-columns: 1fr 1fr;
      grid-gap: rem(16);
      margin-bottom: rem(67);
      padding-left: rem(128);
      padding-right: rem(128);
    }
  }

  &__description {
    margin-top: rem(8);
  }

  &__services {
    display: grid;
    margin-bottom: rem(24);
    grid-template-columns: 1fr;
    grid-auto-rows: fit-content;
    grid-gap: rem(16);

    @include min-media("t") {
      grid-template-columns: 1fr 1fr;
      margin-bottom: rem(40);
    }
    
    @include min-media("d") {
      padding-left: rem(128);
      padding-right: rem(128);
      grid-auto-rows: rem(408);
    }
  }

  &__service {
    width: 100%;
    height: 100%;
    perspective: rem(1000);
    a {
      width: 100%;
      height: 100%;
    }
  }
}
