@use "/src/styles/_variables" as *;
@use "/src/styles/_function" as *;
@use "/src/styles/_media" as *;

.contacts {
    background: radial-gradient(100% 100% at 0% 0%, rgba(11, 32, 101, 0.75) 0%, rgba(11, 32, 101, 0) 100%);
    mix-blend-mode: normal;

    &__section {
        padding: rem(25) rem(16) rem(48);
        color: $color-1;

        @include min-media(t) {
            max-width: rem(1024);
            margin: 0 auto;
            padding: rem(132) rem(32) rem(64);
        }

        @include min-media(d) {
            max-width: rem(1024);
            margin: 0 auto;
            padding: rem(132) 0 rem(64);
        }
    }

    &__heading {
        margin-bottom: rem(29);

        @include min-media(t) {
            margin-bottom: rem(12);
        }
    }

    &__blocks {
        & > * {
            min-height: rem(165);
            margin-bottom: rem(48);

            &:last-child {
                margin-bottom: 0;
            }

            @include min-media(t) {
                width: calc(50% - #{rem(48)});
                margin: rem(79) rem(24) 0;

                &:last-child {
                    margin: rem(79) rem(24) 0;
                }
            }
        }

        @include min-media(t) {
            display: flex;
            flex-wrap: wrap;
            margin: rem(-79) rem(-24) 0;
        }
    }

    &__title {
        margin-bottom: rem(19);

        @include min-media(t) {
            margin-bottom: rem(24);
        }

        &--bank-details {
            @include min-media('t') {
                margin-bottom: rem(16);
            }
        }
    }

    &__list {
        margin: 0;
        padding: 0;
        list-style: none;

        & > * {
            margin-bottom: rem(12);

            &:last-child {
                margin-bottom: 0;
            }

            @include min-media('t') {
                margin-bottom: rem(16);
            }
        }

        &--info {
            margin: 0 0 rem(7) 0;

            @include min-media('t') {
                margin: 0 0 rem(13) 0;
            }
        }

        &--addresses {
            & > * {
                margin-bottom: rem(19);

                @include min-media('t') {
                    margin-bottom: rem(27);
                }
            }
        }

        &--bank-details {
            & > * {
                margin-bottom: 0;
            }
        }
    }

    &__text + &__value {
        margin-left: rem(5);
    }

    &__text + &__link {
        margin-top: rem(5);

        @include min-media('t') {
            margin-top: rem(12);
        }
    }

    &__link {
        display: inline-flex;
        align-items: center;
        transition: color 0.3s ease, opacity 0.3s ease;

        &:hover {
            color: $color-5;

            .contacts__link-icon {
                svg {
                    fill: $color-5;
                }
            }
        }

        &--addresses {
            &:hover {
                color: $color-1;
                opacity: 0.7;

                .contacts__link-icon {
                    svg {
                        fill: $color-1;
                        opacity: 0.7;
                    }
                }
            }
        }
    }

    &__link-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: rem(8);
        height: rem(32);
        margin-left: rem(12);
        margin-bottom: rem(-3);
        background-color: rgba($color-1, 0);
        transition: background-color 0.3s ease;
        flex-shrink: 0;
        transition: color 0.3s ease;

        svg {
            width: 100%;
            height: 100%;
            display: block;
            fill: $color-1;
            transition: fill 0.3s ease;
        }

        @include min-media('t') {
            margin-left: rem(16);
        }

        &--addresses {
            height: rem(8);
            margin-left: rem(9);

        }
    }

    &__info {
        min-height: rem(238);

        @include min-media(t) {
            padding-top: rem(28);
        }
    }

    &__addresses {
        min-height: rem(254);
        padding: rem(16);
        border-radius: rem(12);
        background-color: $color-5;

        @include min-media(t) {
            padding: rem(26) rem(28) rem(32) rem(32);
        }
    }
}