@use "/src/styles/_variables" as *;
@use "/src/styles/_function" as *;
@use "/src/styles/_media" as *;

.socials {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: rem(-4);

    @include min-media('t') {
        margin: 0;
    }

    &__item {
        width: rem(48);
        height: rem(48);
        border-radius: 50%;
        display: block;
        margin: rem(4);

        @include min-media('t') {
            margin: 0;
        }

        & + & {
            @include min-media('t') {
                margin-left: rem(11);
            }

            @include min-media('d') {
                margin-left: rem(8);
            }
        }

        @include min-media('d') {
            width: rem(32);
            height: rem(32);
        }
    }

    &__icon {
        width: 100%;
        height: 100%;
        display: block;
        fill: $color-1;
        stroke: $color-1;
    }

    &--type-contacts {
        flex-wrap: wrap;
        margin: 0 rem(-8);

        .socials__item {
            width: rem(40);
            height: rem(40);
            margin: rem(16) rem(8) 0;

            &:hover {
                .socials__icon {
                    fill: $color-1;
                    stroke: $color-5;
                    background-color: $color-5;
                }
            }

            @include min-media(t) {
                width: rem(48);
                height: rem(48);
            }
        }

        .socials__icon {
            fill: $color-4;
            background-color: $color-1;
            border-radius: 50%;
            transition: fill 0.3s ease, background-color 0.3s ease, stroke 0.3s ease;
        }
    }
}

