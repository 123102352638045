@use "/src/styles/_variables" as *;
@use "/src/styles/_function" as *;
@use "/src/styles/_media" as *;

$parent: "card-review";

.#{$parent} {
    color: $color-1;
    will-change: transform;
    perspective: rem(1000);

    &__heading {
        display: flex;
        align-items: center;
    }

    &__logo {
        width: rem(64);
        height: rem(64);
        border-radius: 50%;
        overflow: hidden;
        object-fit: cover;
        flex-shrink: 0;
        order: 1;

        &:first-child {
            z-index: 1;
        }

        @include min-media('t') {
            order: initial;
        }

        & + & {
            margin-left: rem(-15);
        }
    }

    &__wrapper {
        margin-right: auto;
        order: 0;

        @include min-media('t') {
            margin-left: rem(16);
            margin-right: 0;
            order: initial;
        }
    }

    &__description {
        color: rgba($color-1, 0.5);
        display: block;
    }

    &__text {
        margin-top: rem(8);
    }

    &__button {
        margin-top: rem(20);
        display: flex;
        align-items: center;
        color: inherit;
        cursor: pointer;
    }

    &__button-icon {
        width: rem(24);
        height: rem(24);
        fill: rgba($color-1, 0);
        margin-right: rem(8);
    }

    &--align-center {
        @include min-media("md") {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .#{$parent}__heading {
            @include min-media("md") {
                display: block;
            }
        }

        .#{$parent}__wrapper {
            @include min-media("md") {
                margin-left: 0;
            }
        }

        .#{$parent}__logo {
            @include min-media('md') {
                position: relative;
            }
        }
        
        .#{$parent}__text {
            @include min-media('d') {
                padding: 0 rem(64);
                margin: rem(65) 0 rem(78);
              }
            @include min-media('md') {
                margin-top: rem(16);
            }
        }
    }

    &--full-view {
        .#{$parent} {
            &__text {
                @include min-media('d') {
                    padding: 0;
                    margin: rem(65) 0 rem(78);
                  }
                @include min-media('md') {
                    margin-top: rem(16);
                }
            }
        }
    }
}
