@use "/src/styles/_variables" as *;
@use "/src/styles/_function" as *;
@use "/src/styles/_media" as *;

$parent: "base-slider";

.#{$parent} {
  &__pagination {
    &.swiper-pagination {
      position: static;
      text-align: left;
    }
  }

  & .swiper-pagination-bullet {
    width: rem(41);
    max-width: rem(8);
    height: rem(8);
    background-color: $color-1;
    border-radius: rem(5);
    opacity: 0.5;
    transition: max-width 0.3s ease, opacity 0.3s ease;
    margin-right: rem(8);

    &.swiper-pagination-bullet-active {
        max-width: rem(41);
        opacity: 1;
    }
  }

  &__navigation {
    margin-top: rem(24);

    &--mobile-view {
      @include min-media("t") {
        display: none;
      }
    }
  }

  &--mobile-view {
    .#{$parent} {
      &__slide {
        width: 90%;

        &:last-child {
          width: 100%;
        }

        @include min-media("t") {
          width: 100%;
        }
      }

      &__navigation {
        @include min-media("t") {
          display: none;
        }
      }
    }
  }
}
