@use "/src/styles/_variables" as *;
@use "/src/styles/_function" as *;
@use "/src/styles/_media" as *;
@use "/src/styles/_grids" as *;

.release {
    &.container {
        @include min-media('t') {
            padding: 0 rem(32) rem(16) rem(32);
        }

        @include min-media('d') {
            padding: 0 rem(64) rem(32) rem(64);
        }
    }

    &__heading {
        @include min-media('d') {
            padding: 0 rem(62) 0 rem(69);
        }

        &--r-shrink {
           display: block;
            @include min-media('t') {
                grid-template-columns: minmax(auto, 1.1fr) 1fr;
            }

            @include min-media('d') {
                padding: 0 rem(34) 0 rem(47);
            }

            & + .release__list {
                @include min-media('d') {
                    margin-top: rem(45);
                }
            }
        }
    }

    &__list {
        margin: rem(35) rem(0) rem(16);

        @include min-media('d') {
            margin: rem(24) rem(0) rem(24);
        }

        & > * {
            transform-style: preserve-3d;
            transform-origin: 0% 0%;
            backface-visibility: hidden;
            transition-property: transform;
            will-change: transform;
            perspective: 100.5rem;

            & > .release__item-wrapper {
                transform-style: preserve-3d;
                transform-origin: 0% 0%;
                backface-visibility: hidden;
                transition-property: transform;
                will-change: transform;
                // perspective: 62.5rem;
            }
        }
    }

    &__item {
        &--video {
            height: rem(205);

            @include min-media('t') {
                height: rem(408);
            }

            @include min-media('d') {
                height: rem(668);
            }
        }
    }

    &__clearfix {
        clear: both;
    }

    &__link {
        margin-top: rem(32);
        text-align: right;
        justify-content: flex-start;

        @include min-media('t') {
            padding: 0 rem(16);
            justify-content: flex-end;
        }

        @include min-media('d') {
            padding: 0 rem(62) 0 rem(69);
        }
    }
}
