@use "/src/styles/_variables" as *;
@use "/src/styles/_function" as *;
@use "/src/styles/_media" as *;

$parent: "detail-blogs";

.#{$parent} {
  position: relative;
  padding-top: rem(24);
  padding-bottom: rem(24);

  @include min-media("t") {
    padding-top: rem($header_s + 20);
    padding-bottom: rem(49);
  }

  &__content {
    position: relative;
    z-index: 1;
  }

  &__head {
    @include min-media(d) {
      padding: 0 rem(228);
    }
  }

  &__label {
    display: block;
    margin-bottom: rem(12);
    font-size: rem(16);
    line-height: rem(24);
  }

  &__title {
    margin-bottom: rem(16);

    @include min-media(t) {
      margin-bottom: rem(32);
    }
  }

  &__image-wrapper {
    display: block;
    padding-bottom: rem(32);
    aspect-ratio: 16/9;

    @include min-media("d") {
      padding: 0 rem(128) rem(40);
      height: rem(606);
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    border-radius: rem(12);
    object-fit: cover;
  }

  &__html-content {
    @include min-media("d") {
      padding: 0 rem(64);

      & > *:not(.swiper, iframe, video, img) {
        min-width: rem(816);
        width: min-content;
        margin-left: auto;
        margin-right: auto;
      }
    }

    h1 {
      font-weight: 700;
      font-size: rem(32);
      line-height: rem(40);
      @include min-media("t") {
        font-size: rem(53);
        line-height: rem(64);
      }
    }

    h2 {
      font-weight: 700;
      font-size: rem(24);
      line-height: rem(32);
      margin-bottom: rem(8);
      @include min-media("t") {
        font-size: rem(48);
        line-height: rem(56);
        margin-bottom: rem(16);
      }
    }

    h2 + p {
      font-weight: 400;
      font-size: rem(22);
      line-height: rem(32);

      @include min-media("t") {
        font-size: rem(24);
        line-height: rem(32);
        margin-bottom: rem(18);
      }
    }

    h3 {
      font-weight: 700;
      font-size: rem(24);
      line-height: rem(32);
      margin-bottom: rem(8);
      @include min-media("t") {
        font-size: rem(40);
        line-height: rem(48);
        margin-bottom: rem(24);
        width: 83%;
      }
    }
    h3 + p {
      font-weight: 400;
      margin-bottom: rem(8);
      @include min-media("t") {
        margin-bottom: rem(24);
      }
    }

    h4 {
      font-weight: 700;
      font-size: rem(24);
      line-height: rem(24);
      margin-bottom: rem(16);
      @include min-media("t") {
        font-size: rem(32);
        line-height: rem(40);
      }
    }

    h5 {font-weight: 700;
      font-size: rem(18);
      line-height: rem(24);
      margin-bottom: rem(16);
      @include min-media("t") {
        font-weight: 700;
        font-size: rem(18);
        line-height: rem(24);
      }
    }

    p {
      font-weight: 400;
      font-size: rem(21);
      line-height: rem(29);
      margin-bottom: rem(24);

      &:empty {
        margin-bottom: 0;
      }
    }

    p + ol {
      @include min-media("t") {
        margin-top: rem(8);
      }
    }

    li {
      font-weight: 400;
      font-size: rem(21);
      line-height: rem(29);

    }
    li:not(:last-child) {
      margin-bottom: rem(16);
      @include min-media("t") {
        margin-bottom: rem(24);
      }
    }
    li > ul {
      @include min-media("t") {
        margin-bottom: rem(8);
      }
    }
    ul > li:not(:last-child) {
      @include min-media("t") {
        margin-bottom: rem(16);
      }
    }
    ul {
      @include min-media("t") {
        margin-bottom: rem(24);
      }

      @include min-media("d") {
        padding-left: rem(32);
      }
    }
    blockquote {
      position: relative;
      font-weight: 400;
      font-size: rem(24);
      line-height: rem(32);
      margin: rem(24) 0 rem(40) 0;
      padding-left: rem(32);
      background: url($quotes-icon) no-repeat left top;
      background-size: rem(24);

      @include min-media("t") {
        margin-bottom: rem(80);
      }
    }
    table {
      border-collapse: collapse;
      width: rem(816);
      margin: 0 auto;

      tr:first-child {
        td {
          border-bottom: rem(1) solid  $color-16;
          color: $color-1;
        }
      }

      thead + tbody {
        tr:first-child {
          td {
            border-bottom: rem(1) solid  rgba(255, 255, 255, .2);
          }
        }
      }
    }
    th {
      width: rem(204);
      border-bottom: rem(1) solid  $color-16;
      font-weight: 400;
      font-size: rem(16);
      line-height: rem(24);
      text-align: left;
      padding: rem(12) rem(8);
    }
    td {
      width: rem(204);
      border-bottom: rem(1) solid  rgba(255, 255, 255, .2);
      font-weight: 400;
      font-size: rem(16);
      line-height: rem(24);
      color: $color-12;
      padding: rem(12) rem(8);
    }

    & .table-wrapper {
      overflow-x: scroll;
      margin-bottom: rem(80);
      @include min-media("t") {
        overflow-x: hidden;
      }
    }

    & .expert {
      margin-bottom: rem(25);
      display: flex;
      justify-content: space-between;
      border: rem(4) solid rgba(255, 255, 255, 0.2);
      border-radius: rem(12);
      padding: rem(16) rem(16) rem(16) rem(32);
      align-items: center;

      & > div {
        margin-right: 8px;

        div:nth-child(1) {
          font-weight: 400;
          font-size: rem(12);
          line-height: rem(16);
          color: $color-8;
        }
        div:nth-child(2) {
          font-weight: 700;
          font-size: rem(18);
          line-height: rem(24);
        }
        div:nth-child(3) {
          font-weight: 400;
          font-size: rem(16);
          line-height: rem(24);
        }
      }

      & img {
        width: rem(64);
        height: rem(64);
        border-radius: rem(200);
        @include min-media("t") {
          width: rem(104);
        height: rem(104);
        }
      }
    }

    & .swiper {
      margin-bottom: rem(48);
      padding-bottom: rem(56);

      @include min-media("t") {
        margin-bottom: rem(64);
        padding-bottom: rem(72);
      }

      .swiper-slide {
        border-radius: rem(12);
        overflow: hidden;
        aspect-ratio: 16/9;

        img {
          object-fit: cover;
          object-position: center;
          height: 100%;
          width: 100%;
        }
      }

      .swiper-button-prev,
      .swiper-button-next {
        width: rem(40);
        height: rem(40);
        background-color: rgba($color-1, 0.2);
        background-image: url($arrow-icon);
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 50%;
        margin-top: 0;
        transform: none;
        position: absolute;
        top: auto;
        left: auto;
        right: auto;
        bottom: 0;

        &:after {
            content: none !important;
        }
      }

      .swiper-button-prev {
        left: 0;
        transform: rotate(180deg);

        @include min-media("d") {
          left: rem(64);
        }
      }

      .swiper-button-next {
        left: rem(48);

        @include min-media("d") {
          left: rem(112);
        }
      }
    }

    & iframe,
    & video {
      width: 100%;
      height: 100%;
      margin-bottom: rem(40);
      aspect-ratio: 16/9;
      border-radius: rem(12);
      
      @include min-media("t") {
        margin-bottom: rem(64);
      }
      
      @include min-media("d") {
        max-width: rem(1152);
        width: 100vw;
      }
    }

    ol {
      margin-bottom: rem(48);
      @include min-media("d") {
        padding-left: rem(24);
      }
    }

    & .banner {
      margin-bottom: rem(48);
      padding: rem(24);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      background: linear-gradient(110.39deg, #0BD0FF 3.15%, #7F4EF7 57.15%);
      border-radius: rem(12);

      @include min-media("t") {
        flex-direction: row;
        padding: rem(32);
      }

      & span {
        font-weight: 700;
        font-size: rem(24);
        line-height: rem(24);
        margin-bottom: rem(8);

        @include min-media("t") {
          font-size: rem(32);
          line-height: rem(40);
          margin-right: rem(24);
          margin-bottom: 0;
        }
      }

      & a {
        display: block;
        font-weight: 400;
        padding: rem(12);
        background-color: $color-1;
        color: $color-4;
        border-radius: rem(53);
        font-size: rem(16);
        line-height: rem(24);
        text-align: center;
        width: 100%;

        @include min-media("t") {
          min-width: rem(264);
          width: auto;
        }
      }
    }

    & img {
      width: 100%;
      height: auto;
      border-radius: rem(12);

      @include min-media("d") {
        max-width: rem(1152);
        width: 100vw;
        object-fit: fill;
      }
    }
  }

  &__gradient--tl {
    display: block;
    top: rem(-400);
    left: rem(-400);

    @include max-media('dMax') {
        width: rem(900) !important;
        height: rem(900) !important;
    }

    @include min-media('xl') {
        top: rem(-897);
        left: rem(-494);
    }

  }
  &__gradient--tr {
    top: rem(-400);
    right: rem(-400);
    left: auto;
    @include min-media('xl') {
        top: rem(-657);
        right: rem(-718);
    }
  }

  &__section {
    margin-top: rem(37);
  }

  &__sharing {
    margin-top: rem(40);

    @include min-media("d") {
      padding: 0 rem(232);
    }

    &-title {
      font-weight: 400;
      font-size: rem(24);
      line-height: rem(32);
    }
    &-social {
      .socials__item {
        width: rem(48);
        height: rem(48);
        margin-right: rem(16);
      }
      .socials__icon {
        fill: $color-4;
        background-color: $color-1;
        border-radius: 50%;
        transition: fill 0.3s ease, background-color 0.3s ease, stroke 0.3s ease;
      }
    }

    &-head {
      display: flex;
      flex-direction: column-reverse;
      margin-bottom: rem(8);

      @include min-media("d") {
        align-items: center;
        flex-direction: row;
        margin-bottom: rem(16);
        justify-content: space-between;
      }
    }
  }

  &__blog-date {
    margin-bottom: rem(40);
    opacity: 0.5;

    @include min-media("d") {
      margin-bottom: 0;
    }
  }

  &__projects {
    &-title {
      text-align: center;
      width: 100%;
      margin-bottom: rem(16);

      @include min-media("d") {
        margin-bottom: rem(40);
      }
    }
    &-item {
      perspective: rem(1000);
      width: 100%;
      .card-event {
        width: 100%;
      }
      @include min-media("t") {
        width: calc((100% - 24px) / 2);
        &:not(:last-child) {
          margin-right: rem(24);
        }
      }
      &:not(:last-child) {
        margin-bottom: rem(16);
      }

    }
    &-list {
      display: flex;
      flex-direction: column;
      @include min-media("t") {
        flex-direction: row;
        justify-content: center;
      }
    }
  }

  &__design {
    height: rem(186);
    position: absolute;
    display: none;
    @include min-media("t") {
      display: block;
    }
    &--tr {
      top: rem(1176);
      right: rem(-60);
      @include min-media("d") {
        right: rem(-150);
      }
    }
    &--tl {
      top: rem(1648);
      left: rem(-60);
      @include min-media("d") {
        left: rem(-150);
      }
    }
  }
}
