@use "/src/styles/_variables" as *;
@use "/src/styles/_function" as *;
@use "/src/styles/_media" as *;

.works {
    min-height: 100vh;
    padding-top: rem(32);
    background: $gradient-color-1;
    z-index: 1;
    position: relative;

    @include min-media("t") {
        padding-top: rem($header + 32);
    }

    @include min-media("d") {
        padding-top: rem($header + 61);
    }

    &:after {
        content: '';
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        background: radial-gradient(rgba(255, 0, 122, 0.5) 1%, rgba(255, 0, 122, 0) 70%) no-repeat left -20vw top -20vw,
                radial-gradient(rgba(255, 0, 122, 0.5) 1%, rgba(255, 0, 122, 0) 70%) no-repeat right -20vw top -20vw,
                radial-gradient(rgba(255, 0, 122, 0.5) 1%, rgba(#900E54, 0) 70%) no-repeat bottom -30vw right -20vw;
        background-size: 50vw 50vw, 50vw 50vw, 50vw 50vw;
        background-attachment: fixed;
        pointer-events: none;
        position: fixed;
    }

    &__head {
        display: grid;
        color: $color-1;
        margin-bottom: rem(16);

        @include min-media("d") {
            grid-template-columns: 1.15fr 0.9fr;
            grid-gap: rem(123);
            margin-bottom: rem(51);
        }

        &.container {
            @include min-media('t') {
                padding: 0 rem(32);
            }

            @include min-media('d') {
                // padding: 0 rem(64);
                padding: 0 rem(128);
            }
        }
    }

    &__description {
        margin-top: rem(8);
    }

    &__loader {
        width: rem(112);
        height: rem(112);
        display: block;
        margin: rem(52) auto;
        animation: levitation-work 2s ease-in-out infinite 0s;
        position: absolute;
        bottom: 0;
        left: 50%;
        z-index: 1;

        @include min-media("t") {
            width: rem(240);
            height: rem(240);
        }
    }

    &__list {
        display: flex;
        margin: rem(-8) rem(-4);
        overflow: auto;
        position: relative;
        z-index: 1;

        @include min-media("t") {
            flex-wrap: wrap;
        }

        @include min-media("d") {
            margin-left: auto;
            margin-right: auto;
        }

        & > * {
            width: auto;
            margin: rem(8) rem(4);
            flex-shrink: 0;

            &:first-child {
                @include max-media("mMax") {
                    margin-left: 0;
                }
            }

            &:last-child {
                @include max-media("mMax") {
                    margin-right: 0;
                }
            }
        }

        &.container {
            @include min-media('t') {
                padding: 0 rem(26);
            }

            @include min-media('d') {
                padding: 0 rem(120);
            }
        }
    }

    &__items {
        margin-top: rem(27);
        position: relative;
        z-index: 1;
    }

    &__button-wrp {
        position: fixed;
        left: 0;
        bottom: rem($header_s);
        height: rem(40);
        z-index: 1;
        opacity: 0;
        visibility: hidden;
        transition: .3s linear opacity, .3s linear bottom;

        @include max-media("mMax") {
            min-width: 100%;
            z-index: 1;
            width: 100%;
            height: rem(56);
            padding: rem(8) rem(16);
            background-color: rgba(203, 203, 203, 0.05);
            border-top: 1px solid rgba(255, 255, 255, 0.5);

            @supports (backdrop-filter: blur(18px)) {
                backdrop-filter: blur(18px);
            }
        }

        @include min-media("t") {
            left: 50%;
            transform: translateX(-50%);
            bottom: rem(22);
            height: rem(48);
        }

        &--visible {
            visibility: visible;
            opacity: 1;
        }
    }

    &__button {
        height: rem(40);
        min-width: 100%;

        @include max-media("mMax") {
            background-color: rgba(255, 255, 255, 0);
            color: rgb(255, 255, 255);
            border: 1px solid white;
        }

        @include min-media("t") {
            height: rem(48);
            min-width: rem(194);
        }
    }

    &__gradient {
        z-index: 1;
        display: none;

        @include min-media('t') {
            display: block;
        }

        &--mr {
            top: rem(1100);
            right: rem(-600);
            left: auto;

            @include min-media('xl') {
                top: rem(1900);
                right: rem(-718);
                left: auto;
            }
        }
    }

    &__bg {
        display: none;
        position: absolute;
        left: 0;
        width: 100%;
        top: rem(2221);
        align-items: center;
        justify-content: center;

        &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            height: 50%;
            background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #081021);
            background-repeat: no-repeat;
            background-size: 100%;
        }

        @include min-media("t") {
            display: flex;
        }
    }
}

@keyframes levitation-work {
    0% {
        transform: translateY(0) translateX(-50%);
    }

    50% {
        transform: translateY(30px) translateX(-50%);
    }

    100% {
        transform: translateY(0) translateX(-50%);
    }
}
