@use "/src/styles/_variables" as *;
@use "/src/styles/_function" as *;
@use "/src/styles/_media" as *;

$popup: 'popup';

.header {
    color: $color-1;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.6);
    border-bottom: 1px solid rgba($color-1, 0.5);
    transition: opacity 0.6s;

    @supports (backdrop-filter: blur(18px)) {
        background-color: rgba(203, 203, 203, 0.05);
        backdrop-filter: blur(18px);

        @include min-media('d') {
            background-color: rgba($color-1, 0);
        }
    }

    @include max-media('mMax') {
        top: auto;
        bottom: 0;
        border-top: 1px solid rgba($color-1, 0.5);
        border-bottom: none;
    }

    @include min-media('d') {
        border-bottom: none;
    }

    &__wrapper {
        max-width: rem($container);
        height: rem(64);
        display: flex;
        align-items: center;
        margin: 0 auto;
        padding: rem(12) rem(16);

        @include min-media('t') {
            padding: rem(12) rem(16);
        }

        @include min-media('d') {
            padding: rem(16) rem(64);
            height: rem(72);
        }
    }

    &__controls {
        align-items: center;
        margin-left: auto;
        font: 400 rem(16)/rem(24) 'Journal Sans New', sans-serif;
        display: flex;
        column-gap: rem(16);
    }

    &__logo {
        display: flex;
        align-items: center;
        height: rem(24);

        @include max-media('mMax') {
            width: rem(40);
            overflow: hidden;
        }

        @include min-media('t') {
            height: rem(28);
        }

        @include min-media('d') {
            height: rem(22);
        }
    }

    &__logo-img{
        height: 100%;
    }

    &__nav {
        margin-left: rem(24);
        display: none;

        @include min-media('d') {
            display: block;
        }
    }

    &__item {
        font: 700 rem(17)/rem(24) 'Journal Sans New', sans-serif;

        & + & {
            margin-left: rem(16);
        }

        &--active {
            opacity: 0.5;
        }
    }

    &__lang {
        margin-left: rem(16);
        display: none;

        @include min-media('d') {
            display: block;
        }
    }

    &__link {
        display: none;

        @include min-media('d') {
            display: block;
        }
    }

    &__menu {
        margin-left: auto;
        position: relative;
        width: rem(32);
        height: rem(32);
        display: block;
        order: 2;

        &:before,
        &:after {
            content: '';
            width: rem(24);
            height: rem(3);
            background-color: $color-1;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: transform 0.3s ease;
        }

        &:before {
            top: calc(50% - #{rem(4)});
        }

        &:after {
            top: calc(50% + #{rem(4)});
        }

        &--active {
            &:before {
                transform: translate(-50%, calc(-50% + #{rem(4)})) rotate(-45deg);
            }

            &:after {
                transform: translate(-50%, calc(-50% - #{rem(4)})) rotate(45deg);
            }
        }

        @include min-media('t') {
            display: block;
            margin-right: rem(44);
            order: initial;
        }

        @include min-media('d') {
            display: none;
        }
    }

    &__button {
        border-radius: rem(53);
        width: rem(162);
        height: rem(40);
        background-color: $color-1;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $color-2;
        font: 400 rem(16)/rem(24) 'Journal Sans New', sans-serif;

        @include max-media('mMax') {
            width: rem(178);
        }
    }

    &--showreel-opened {
        opacity: 0;
        pointer-events: none;
    }
}

