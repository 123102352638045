@use "/src/styles/_variables" as *;
@use "/src/styles/_function" as *;
@use "/src/styles/_media" as *;

.button {
    border-radius: rem(53);
    width: rem(162);
    background-color: $color-1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-2;
    font: 400 rem(16)/rem(24) 'Journal Sans New', sans-serif;
    transition: color 0.3s ease, background-color 0.3s ease;
    flex-wrap: wrap;

    &:hover,
    &:active {
        color: $color-5;
    }

    &:disabled {
        background-color: $color-9;
        color: $color-10;
        pointer-events: none;
    }
    &__text {
        pointer-events: none;
    }
}