@use "/src/styles/_variables" as *;
@use "/src/styles/_function" as *;
@use "/src/styles/_media" as *;

$parent: "tooltip";

.#{$parent} {
  width: rem(240);
  padding: 8px;
  border-radius: 8px;
  background-color: $color-4;
  z-index: 10;
  box-shadow: 0px 0px 12px $opacity-color-2;
  color: $color-1;
  font: 400 rem(12)/rem(16) 'Journal Sans New', sans-serif;

  & .tippy-svg-arrow {
    top: -4px;
  }

  &[data-placement=top] {
    & .tippy-svg-arrow {
      top: auto;
      bottom: -4px;
    }
  }

  &__arrow {
    background: $color-4;
    width: rem(12);
    height: rem(12);
    transform: rotate(45deg) skew(15deg, 15deg);
    border-radius: 4px;
    z-index: 9;
  }
}
