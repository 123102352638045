@use "/src/styles/_variables" as *;
@use "/src/styles/_function" as *;
@use "/src/styles/_media" as *;

.showreel-opener {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    pointer-events: none;

    @include max-media('mMax') {
        position: absolute;
        height: auto;
        bottom: 0;
        top: 0;
    }

    &--opened {
        @include max-media('mMax') {
            position: fixed
        }
    }

    &__button {
        border-radius: 50%;
        width: rem(55);
        height: rem(55);
        display: flex;
        align-items: center;
        justify-content: center;
        left: rem(12);
        bottom: 150px;
        opacity: 1;
        position: absolute;
        cursor: pointer;
        pointer-events: all;

        @include max-media('mMax') {
            position: fixed;
            bottom: rem(80);
            z-index: 99;
        }

        @include min-media('t') {
            width: rem(101);
            height: rem(101);
            top: rem(538);
            left: rem(25);
            opacity: 0;
        }

        @include min-media('xl') {
            width: rem(187);
            height: rem(187);
            top: rem(333);
            opacity: 0;
        }

        @include min-media('xxl') {
            left: rem(222);
        }
    }

    &__image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__icon {
        fill: $color-1;
        width: rem(14);
        height: rem(14);
        position: relative;
        z-index: 2;

        @include min-media('t') {
            width: rem(29);
            height: rem(29);
        }

        @include min-media('xl') {
            width: rem(42);
            height: rem(42);
        }
    }
}

.showreel {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    border-radius: 50%;
    overflow: hidden;
    transform: scale(0);
    pointer-events: all;
    background-color: $color-17;

    &__video {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    &__close {
        width: rem(32);
        height: rem(32);
        position: absolute;
        right: rem(16);
        top: rem(16);
        z-index: 2;
        cursor: pointer;
        opacity: 0;
        filter: drop-shadow(0 0 0 $color-2);

        svg {
            width: rem(32);
            height: rem(32);
        }
    }
}
