@use "/src/styles/_variables" as *;
@use "/src/styles/_function" as *;
@use "/src/styles/_media" as *;

.menu {
    background-color: $color-4;
    width: 100%;
    height: 100vh;
    padding-bottom: rem(64);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 4;
    color: $color-1;

    @include min-media('t') {
        padding-bottom: 0;
        padding-top: rem(64);
    }

    &__wrapper {
        overflow: auto;
        height: 100%;
    }

    &__item {
        border-bottom: 1px solid rgba($color-1, 0.2);
        padding: rem(16) rem(24);
        display: block;

        &--active {
            opacity: 0.5;
            border-bottom-color: rgba($color-1, 0.4);
        }
    }

    &__controls {
        margin-top: rem(16);
        margin-bottom: rem(16);
        
        & > * {
            display: block;
            padding: rem(8) rem(24);
        }
    }
}
