@use "./src/styles/_variables" as *;
@use "/src/styles/_fonts" as *;
@use "/src/styles/_base" as *;
@use "/src/styles/_media" as *;
@use "/src/styles/function" as *;

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Journal Sans New', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $color-1;
  background-color: black;
  min-height: 100vh;
  scroll-padding-top: rem(160);
}

a {
  color: inherit;
  text-decoration: none;
}

button,
input,
textarea,
select {
    background: none;
    font-family: 'Journal Sans New', sans-serif;
    border: 0;
    border-radius: 0;
    outline: 0;
    transition: border 0.3s ease,color 0.3s ease;
    cursor: pointer;
    padding: 0;
}

h1, h2, h3, p {
  margin: 0;
}

* {
  box-sizing: border-box;
}

body {
  background-image: $gradient-color-1;
}

section {
  position: relative;
  z-index: 1;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

#messages {
  position: fixed;
  right: 0;
  z-index: 99999;
  right: 50%;
  transform: translateX(50%);
  bottom: calc(#{rem($header_s + 12)});
  
  @include min-media('t') {
    top: rem(72);
    right: rem(8);
    bottom: initial;
    transform: none;
}

  @include min-media('d') {
      top: rem(80);
  }
}
