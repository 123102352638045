@use "/src/styles/_variables" as *;
@use "/src/styles/_function" as *;
@use "/src/styles/_media" as *;

$parent: 'link';

.#{$parent} {
    color: $color-1;
    font: 400 rem(22)/rem(32) 'Journal Sans New', sans-serif;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    @include min-media('t') {
        font-size: rem(20);
        line-height: rem(24);
    }

    @include min-media('d') {
        font-size: rem(24);
        line-height: rem(32);
    }

    &__text {
        & + .#{$parent}__icon {
            margin-left: rem(5);

            @include min-media('t') {
                margin-left: rem(8);
            }
        }
    }

    &__subtext {
        width: 100%;
        text-align: center;
    }

    &__icon {
        width: rem(32);
        height: rem(32);
        border-radius: 50%;
        border: 1px solid $color-1;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba($color-1, 0);
        transition: background-color 0.3s ease;
        flex-shrink: 0;

        @include min-media('d') {
            width: rem(40);
            height: rem(40);
        }

        svg {
            width: 100%;
            height: 100%;
            display: block;
            fill: $color-1;
            transition: fill 0.3s ease;

            @include min-media('d') {
                width: rem(30);
                height: rem(30);
            }
        }
    }

    &:hover {
        .#{$parent}__icon {
            background-color: $color-1;

            svg {
                fill: $color-4;
            }
        }
    }

    &:active {
        .#{$parent}__icon {
            background-color: rgba($color-1, 0);

            svg {
                fill: $color-1;
            }
        }
    }

    &--with-bg {
        border-radius: rem(53);
        padding: rem(8) rem(24);
        min-height:rem(40);
        background-color: $color-1;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $color-2;
        font: 400 rem(16)/rem(24) 'Journal Sans New', sans-serif;
        transition: color 0.3s ease, background-color 0.3s ease;

        &:hover,
        &:active {
            background-color: $color-14;
            // color: $color-5;
        }
    }

    &--no-bg {
        border-radius: rem(53);
        // padding: rem(8) rem(24);
        min-height:rem(40);
        background-color: rgba($color-1, 0);
        border: 1px solid $color-1;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $color-1;
        font: 400 rem(16)/rem(24) 'Journal Sans New', sans-serif;
        transition: color 0.3s ease, background-color 0.3s ease;

        &:hover,
        &:active {
            background-color: $color-1;
            color: $color-5;
        }
    }

    &--primery {
        background-color: $color-5;
        color: $color-1;

        &:hover {
            background-color: $color-14;
            color: $color-1;
        }

        &:active {
            background-color: $color-15;
        }
    }
}