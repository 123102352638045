@keyframes levitation {
  0% {
      transform: translateY(0);
  }

  50% {
      transform: translateY(30px);
  }

  100% {
      transform: translateY(0);
  }
}

@keyframes slider-ufo-light {
    0% {
        opacity: 0.3
    }

    100% {
        opacity: 1;
    }
}