@use "/src/styles/_variables" as *;
@use "/src/styles/_function" as *;
@use "/src/styles/_media" as *;

$team-animation-duration: 0.3s;

.team {
    padding-top: rem(259);
    padding-bottom: rem(200);
    position: relative;
    color: $color-1;
    max-width: rem(1220);
    margin: 0 auto;
    z-index: 3;

    @include max-media(mMax) {
        padding-top: rem(109);
    }

    &__rocket {
        position: absolute;
        top: rem(25);
        right: rem(88);

        @include max-media(mMax) {
            top: rem(-55);
            width: rem(82);
            right: rem(29);
        }
    }

    &__planet {
        position: absolute;
        top: rem(-143);
        left: rem(126);
        width: rem(130);
        height: rem(130);
        border-radius: 50%;
        background: linear-gradient(334deg, #356794 -30%, rgba(42, 83, 122, 0) 49.54%);
        filter: blur(rem(4));

        @include max-media(mMax) {
            top: rem(-120);
            left: rem(8);
            width: rem(66);
            height: rem(66);
        }
    }

    &__planet-small {
        position: absolute;
        top: rem(-151);
        left: rem(290);
        width: rem(38);
        height: rem(38);
        border-radius: 50%;
        background: linear-gradient(334deg, #356794 -30%, rgba(42, 83, 122, 0) 49.54%);
        filter: blur(rem(1));

        @include max-media(mMax) {
            top: rem(-133);
            left: rem(75);
            width: rem(22);
            height: rem(22);
        }
    }

    &::after {
        content: '';
        display: block;
        width: 100vw;
        height: rem(586);
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        background: linear-gradient(180deg, rgba(60, 71, 255, 0.37) 0%, rgba(10, 23, 255, 0) 33.35%);
        filter: blur(16px);
        border-radius: 45%;
        margin-left: calc((100vw - 100%) / -2);
    }

    &__title {
        text-align: center;
        margin-bottom: rem(22);
    }

    &__people {
        position: relative;
        height: rem(654);

        @include max-media(mMax) {
            height: rem(337);
            margin-bottom: rem(126);
        }
    }

    &__item {
        overflow: hidden;
        border-radius: 50%;
        cursor: pointer;
        z-index: 2;

        &:nth-child(1) {
            position: absolute;
            top: calc(11% + #{rem(5)});
            left: calc(26% - #{rem(11)});
            width: rem(40);
            height: rem(40);

            @include max-media(mMax) {
                top: calc(18% - #{rem(2)});
                left: calc(26% - #{rem(10)});
                width: rem(20);
                height: rem(20);
            }
        }

        &:nth-child(2) {
            position: absolute;
            top: calc(15% + #{rem(6)});
            left: 75%;
            width: rem(62);
            height: rem(62);

            @include max-media(mMax) {
                top: calc(25% + #{rem(4)});
                left: calc(88% - #{rem(3)});
                width: rem(30);
                height: rem(30);
            }
        }

        &:nth-child(3) {
            position: absolute;
            top: calc(38% - #{rem(3)});
            left: calc(17% - #{rem(5)});
            width: rem(62);
            height: rem(62);

            @include max-media(mMax) {
                top: calc(36% - #{rem(2)});
                left: calc(-1% - #{rem(5)});
                width: rem(30);
                height: rem(30);
            }
        }

        &:nth-child(4) {
            position: absolute;
            top: calc(42% - #{rem(6)});
            left: calc(80% - #{rem(4)});
            width: rem(62);
            height: rem(62);

            @include max-media(mMax) {
                top: calc(89% - #{rem(9)});
                left: calc(77% - #{rem(5)});
                width: rem(30);
                height: rem(30);
            }
        }

        &:nth-child(5) {
            position: absolute;
            top: calc(57% - #{rem(4)});
            left: calc(24% - #{rem(7)});
            width: rem(40);
            height: rem(40);

            @include max-media(mMax) {
                top: calc(93% - 0.25rem);
                left: calc(14% - 0.4375rem);
                width: rem(20);
                height: rem(20);
            }
        }

        &:nth-child(6) {
            position: absolute;
            top: calc(60% - #{rem(3)});
            left: 73%;
            width: rem(40);
            height: rem(40);

            @include max-media(mMax) {
                top: calc(81% - 0.1875rem);
                left: 93%;
                width: rem(20);
                height: rem(20);
            }
        }

        &:nth-child(7) {
            position: absolute;
            top: calc(17% + #{rem(2)});
            left: calc(5% - #{rem(3)});
            width: rem(62);
            height: rem(62);

            @include max-media(mMax) {
                top: calc(11% - #{rem(1)});
                left: calc(1% + #{rem(10)});
                width: rem(30);
                height: rem(30);
            }
        }

        &:nth-child(8) {
            position: absolute;
            top: calc(17% - #{rem(29)});
            left: calc(91% + #{rem(37)});
            width: rem(40);
            height: rem(40);

            @include max-media(tMax) {
                left: calc(89% + #{rem(37)});
            }

            @include max-media(mMax) {
                top: calc(19% - 1.8125rem);
                left: calc(84% + 2.3125rem);
                width: rem(20);
                height: rem(20);
            }
        }

        &:nth-child(9) {
            position: absolute;
            top: calc(45% + #{rem(4)});
            left: calc(0% - #{rem(3)});
            width: rem(40);
            height: rem(40);

            @include max-media(mMax) {
                top: calc(56% + #{rem(1)});
                left: calc(7% - #{rem(3)});
                width: rem(20);
                height: rem(20);
            }
        }

        &:nth-child(10) {
            position: absolute;
            top: calc(36% + #{rem(7)});
            left: calc(94% + #{rem(8)});
            width: rem(62);
            height: rem(62);

            @include max-media(tMax) {
                left: calc(91% + 0.5rem);
            }
            @include max-media(mMax) {
                top: calc(58% + #{rem(8)});
                left: calc(93% + 0.5rem);
                width: rem(30);
                height: rem(30);
            }
        }

        &:nth-child(11) {
            position: absolute;
            top: calc(60% - #{rem(3)});
            left: calc(10% - #{rem(7)});
            width: rem(62);
            height: rem(62);

            @include max-media(mMax) {
                top: calc(73% - #{rem(1)});
                left: calc(1% - 0.4375rem);
                width: rem(30);
                height: rem(30);
            }
        }

        &:nth-child(12) {
            position: absolute;
            top: calc(62% + #{rem(2)});
            left: calc(89% + #{rem(3)});
            width: rem(40);
            height: rem(40);

            @include max-media(mMax) {
                top: calc(12% + #{rem(5)});
                left: calc(74% + 0.1875rem);
                width: rem(20);
                height: rem(20);
            }
        }
    }

    &__main-image {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -64%);
        width: rem(374);
        height: rem(374);

        @include max-media(tMax) {
            width: rem(324);
            height: rem(324);
        }
        @include max-media(mMax) {
            top: rem(210);
            height: rem(194);
            width: rem(194);
        }
    }

    &__border {
        height: rem(376);
        width: rem(378);
        z-index: 1;
        background: linear-gradient(#F44EF7  0%, #7F4EF7 100%);

        @include max-media(tMax) {
            width: rem(324);
            height: rem(324);
        }
        @include max-media(mMax) {
            height: 12.0624rem;
            width: 12.0624rem;
        }
    }

    &__border-before {
        background: linear-gradient(#F44EF7  0%, #7F4EF7 100%);
        position: absolute;
        opacity: 0.5;
        top: -4%;
        left: -4%;
        height: 108%;
        width: 108%;
    }

    &__border-after {
        background: linear-gradient(#F44EF7  0%, #7F4EF7 100%);
        position: absolute;
        opacity: 0.5;
        top: -2%;
        left: -2%;
        height: 104%;
        width: 104%;
    }

    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__profile-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 0;
        z-index: 3;
        position: absolute;
        top: 0;
        left: 0;
        transition: .5s linear opacity;

        &--view {
            opacity: 1,
        }
    }

    &__profile {
        margin-top: rem(-120);
        position: relative;
    }

    &__wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 4;
        border: rem(1) solid #fff;
        border-radius: 50%;
        overflow: hidden;
    }

    &__profile-item {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        opacity: 0;
        transition: .5s linear opacity;

        &--view {
            opacity: 1;
        }
    }

    &__name {
        text-align: center;
        margin-bottom: rem(10);
    }

    &__content {
        text-align: center;
        max-width: rem(608);
        margin: 0 auto;
    }
}