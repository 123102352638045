@use "/src/styles/_variables" as *;
@use "/src/styles/_function" as *;
@use "/src/styles/_media" as *;

$parent: "breadcrumbs";

.#{$parent} {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: rem(24);
  overflow-x: auto;

  @include min-media("d") {
    margin-bottom: rem(32);
    overflow-x: visible;
  }

  a {
    flex-wrap: nowrap;
    font-size: rem(16);
    line-height: rem(22);
    &:not(:last-child) {
      margin-right: rem(7);
    }

    &:not(:last-child)::after {
      content: '';
      width: rem(4);
      height: rem(4);
      border-radius: 50%;
      margin-left: rem(6);
      background-color: $color-1;
    }
    span {
      white-space: nowrap;
    }
  }
}
