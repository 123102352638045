@use "/src/styles/_variables" as *;
@use "/src/styles/_function" as *;
@use "/src/styles/_media" as *;

.blogs {
    min-height: 100vh;
    position: relative;
    padding: rem(25) rem(16) rem(32);

    @include min-media(t) {
        max-width: rem(1152);
        margin: 0 auto;
        padding: rem(125) rem(32) rem(64);
    }

    @include min-media(d) {
        max-width: rem(1152);
        margin: 0 auto;
        padding: rem(125) 0 rem(64);
    }

    &__heading {
        margin-bottom: rem(29);
        color: $color-1;
        position: relative;
        z-index: 3;

        @include min-media(t) {
            margin-bottom: rem(12);
        }
    }

    &__filter-tags {
        display: flex;
        margin: rem(-8) rem(-4) rem(32);
        overflow: auto;
        position: relative;
        z-index: 3;
        &::-webkit-scrollbar {
            width: 0;
            height: 0;
      }

        @include min-media("t") {
            flex-wrap: wrap;
            margin: rem(-8) rem(-4) rem(56);
        }

        & > * {
            width: auto;
            margin: rem(8) rem(4);
            flex-shrink: 0;

            .filter-tag__text {
                padding: rem(8) rem(46);
            }
            &:first-child {
                @include max-media("mMax") {
                    margin-left: 0;
                }
            }

            &:last-child {
                @include max-media("mMax") {
                    margin-right: 0;
                }
            }
        }
    }
    &__cards {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .card-event {
            width: 100%;
            // margin-bottom: rem(8);
        }
        // @include min-media('t') {
        //     .card-event {
        //         width: calc(100% / 2 - 12px);
        //         margin-bottom: rem(24);
        //     }
        //     .card-event:nth-child(3n) {
        //         width: 100%;
        //     }
        // }
    }
    &__card {
        width: 100%;
        margin-bottom: rem(8);
        perspective: rem(1000);
        opacity: 1;

        &:nth-child(6n - 5) > .card-event > .card-event__container {
            background: linear-gradient(56.12deg, rgba(53, 85, 255, 0.6) 1.06%, rgba(255, 60, 247, 0.6) 100%);
        }

        &:nth-child(6n - 1) > .card-event > .card-event__container {
            background: linear-gradient(56.12deg, rgba(53, 85, 255, 0.6) 1.06%, rgba(255, 60, 247, 0.6) 100%);
        }

        &:nth-child(9n - 6) > .card-event > .card-event__container {
            background: linear-gradient(56.12deg, rgba(0, 178, 255, 0.6) 1.06%, rgba(0, 255, 194, 0.6) 100%)
        }

        @include min-media('t') {
            opacity: 0;

            & {
                width: calc(100% / 2 - 12px);
                margin-bottom: rem(24);
            }
            &:nth-child(3n) {
                width: 100%;
            }
        }
    }
    &__animate {
        display: none;
        position: absolute;
        z-index: 2;
        @include min-media('d') {
            display: block;
        }

    }
    &__rocket {
        pointer-events: none;
        width: rem(186);
        height: rem(186);
        left: rem(1123);
        top: rem(1176);
    }
    &__nlo {
        pointer-events: none;
        width: rem(256);
        height: rem(256);
        left: rem(-144);
        top: rem(3000);
    }
    &__gradient {
        display: none;

        @include min-media('t') {
            display: block;
        }

        &--tl {
            display: block;
            top: rem(-400);
            left: rem(-400);

            @include max-media('dMax') {
                width: rem(900) !important;
                height: rem(900) !important;
            }

            @include min-media('xl') {
                top: rem(-897);
                left: rem(-494);
            }
        }
        &--tr {
            top: rem(-400);
            right: rem(-400);
            left: auto;

            @include min-media('xl') {
                top: rem(-657);
                right: rem(-718);
            }
        }
    }
    &__loader {
        width: rem(112);
        height: rem(112);
        display: block;
        margin: rem(52) auto;
        animation: levitation-blog 1.5s linear infinite 0s;
        position: absolute;
        bottom: 0;
        left: 50%;
        z-index: 1;

        @include min-media("t") {
            width: rem(240);
            height: rem(240);
        }
    }
}

@keyframes levitation-blog {
    0% {
        transform: translateY(0) translateX(-50%);
    }

    50% {
        transform: translateY(30px) translateX(-50%);
    }

    100% {
        transform: translateY(0) translateX(-50%);
    }
}
