@use "/src/styles/_variables" as *;
@use "/src/styles/_function" as *;
@use "/src/styles/_media" as *;

.agency {
    margin: 0 auto;
    background: linear-gradient(180deg, rgba(3, 1, 2, 0) 0%, #030102 16.15%, #030102 88.19%, rgba(3, 1, 2, 0) 100%);
    position: relative;

    @include min-media("t") {
        padding-bottom: rem(64);
    }

    &:after {
        content: '';
        width: 100%;
        height: rem(1533);
        background-image: linear-gradient(to bottom, transparent, black 35%, black 70%, transparent);
        left: 0;
        position: absolute;
        z-index: 1;
    }

    &__entry {
        height: rem(116);
        position: relative;

        @include max-media(sMax) {
            height: rem(32);
            z-index: 0;
        }
        @include min-media(m) {
            height: rem(40);
            z-index: 0;
        }
        @include min-media(t) {
            height: rem(104);
            z-index: 0;
        }
        @include min-media(d) {
            height: rem(116);
        }
    }

    &__entry-planet {
        width: rem(111);
        height: rem(111);
        border-radius: 50%;
        position: absolute;
        top: rem(150);
        right: rem(130);
        background: linear-gradient(rgba(255, 255, 255, .3) 0%, rgba(255, 255, 255, 0) 64.54%);
        transform: rotate(215deg);
        opacity: .4;
        filter: blur(#{rem(4)});
        z-index: 1;
        @include min-media(xxxl) {
            right: 27.125rem;
        }
        @include max-media(dMax) {
            display: none;
        }

        &--small {
            width: rem(55);
            height: rem(55);
            top: rem(260);
            right: rem(63);
            @include min-media(xxxl) {
                right: 22.9375rem;
            }
        }
    }


    &__entry-gradient {
        position: absolute;
        right: 0;
        top: 0;
        background: radial-gradient(ellipse at right, rgba(255, 0, 122, 0.5), transparent);
        width: 100vw;
        height: rem(1640);
        z-index: 0;

        @include max-media(mMax) {
            height: rem(821);
        }

        &--blue {
            background: radial-gradient(at left top, rgba(9 80 139 / 40%) 30%, rgba(30, 115, 185, 0) 62%);
            position: absolute;
            width: 100%;
            height: rem(1640);
            top: 0;
            left: 0;
            z-index: 0;

            @include min-media(xxxl) {
                background: radial-gradient(at top left, rgba(0, 60, 88, 0.3) 32%, rgba(30, 115, 185, 0) 42%);
            }
        }
    }

    &__planet {
        margin-top: 25vh;
        width: 100%;
        height: auto;
        margin-left: 50%;
        pointer-events: none;
        transform: translateX(-50%);

        @include min-media('t') {
            width: 200%;
            transform: none;
            transform: translateX(-50%) rotate(-21deg);
        }
        @include min-media('md') {
            width: 200%;
            transform: none;
            transform: translateX(-50%) rotate(-21deg);
        }

        @include min-media('d') {
            width: initial;
            transform: none;
            margin-top: 24%;
            transform: translateX(-50%)  rotate(-21deg);
        }

        @include min-media(xxxl) {
            margin-top: rem(350);
            transform: translateX(-50%) rotate(-21deg);
        }
    }

    &__heading {
        color: $color-1;
        max-width: rem(1024);
        margin: 0 auto;
        z-index: 3;

        @include max-media(sMax) {
            margin-bottom: rem(295);
        }
        @include min-media(m) {
            margin-bottom: rem(268);
        }
        @include min-media(t) {
            margin-bottom: rem(268);
        }
        @include min-media(d) {
            margin-bottom: rem(396);
        }
    }

    &__title {
        margin-bottom: rem(21);
        text-align: center;

        @include max-media(sMax) {
            margin-bottom: rem(15);
            span {
                display: block;
            }
        }
        @include min-media(m) {
            span {
                display: block;
            }
        }
        @include min-media(t) {
            span {
                display: inline;
            }
        }
    }

    &__description {
        h1 {
            text-align: center;
        }
    }

    &__subtitle {
        text-align: center;
        padding: 0 rem(200);

        @include max-media(sMax) {
            padding: 0 rem(22);
        }
        @include min-media(m) {
            padding: 0 rem(15);
        }
        @include min-media(d) {
            padding: 0 rem(200);
        }
    }
    &__lazy-slider {
        position: relative;
        z-index: 2;

        &::before {
            content: "";
            display: block;
            position: absolute;
            bottom: -12.5rem;
            left: rem(-39);
            background: radial-gradient(rgba(255, 0, 122, 0.3) 1%, rgba(255, 0, 122, 0) 71%);
            width: 60%;
            height: 48.75rem;

            @include max-media(dMax) {
                display: none;
            }
        }
    }

    &__slider {
        margin-top: rem(32);

    }

    &__description {
        display: block;
    }

    &__advantage {
        background: linear-gradient(180deg, rgba(3, 1, 2, 0) 0%, #030102 16.15%, #030102 88.19%, #030102 100%);
        position: relative;

        @include max-media(sMax) {
            padding-bottom: rem(157);
        }
        @include min-media(m) {
            padding-bottom: rem(157);
        }
        @include min-media(t) {
            padding-bottom: rem(146);
        }
        @include min-media(d) {
            padding-bottom: rem(50);
        }
    }

    &__advantage-wrapper {
        position: relative;
        z-index: 2;

        @include max-media(sMax) {
            margin-bottom: rem(48);
        }
        @include min-media(m) {
            margin-bottom: rem(72);
        }
        @include min-media(t) {
            margin-bottom: rem(92);
        }
        @include min-media(d) {
            margin-bottom: rem(110);

            &:last-child {
                margin-bottom: rem(117);
            }
        }
    }

    &__advantage-bcg {
        h1 {
            text-align: center;
        }

        .heading {
            display: block;
        }
    }

    &__advantage-animation {
        display: none;
        position: relative;
        z-index: 3;

        img {
            position: absolute;
            display: none;
            width: 100%;
            max-width: rem(92);
            top: 25rem;
            height: auto;
            left: 0;

            @include min-media('md') {
                top: rem(480);
                max-width: rem(137);
            }
        }

        @include min-media('t') {
            display: block;

            img {
                display: block;
            }
        }
    }

    &__advantage-link {
        justify-content: center;
        margin-top: rem(50);

        @include min-media(d) {
            margin-top: rem(59);
        }
    }

    &__card-logo {
        width: 100% !important;
        height: auto !important;

        @include max-media("mMax") {
            padding: rem(12);
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        & > img {
            height: rem(40);

            @include min-media("t") {
                width: 100%;
                height: rem(96);
            }
        }
    }

    &__parallax {
        position: relative;
        width: 100%;
        max-width: rem(1280);
        margin: 0 auto;
        z-index: 2;
        height: rem(576);
    }

    &__space {
        width: 165%;
        height: auto;
        position: absolute;
        top: rem(-562);
        left: rem(-1138);

        @include max-media(dMax) {
            top: -37.125rem;
            left: -58.125rem;
        }

        @include max-media(tMax) {
            top: rem(-450);
            left: rem(-720);
        }
        @include max-media(mMax) {
            width: 210%;
            top: rem(-277);
            left: rem(-436);
        }
    }

    &__sphere {
        width: rem(170);
        position: absolute;
        top: rem(210);
        left: rem(476);
        height: auto;
        max-width: rem(170);

        @include max-media(dMax) {
            top: -0.875rem;
            left: 26.75rem;
        }

        @include max-media(tMax) {
            width: 8.625rem;
            top: -0.875rem;
            left: 16.75rem;
            max-width: 6.625rem;
        }
        @include max-media(mMax) {
            width: rem(65);
            position: absolute;
            top: calc(-2% + #{rem(3)});
            left: calc(39% + #{rem(4)});
            height: auto;
            max-width: rem(65);
        }

        &--small {
            top: 10%;
            left: calc(50% - #{rem(35)});
            width: rem(100);

            @include max-media(dMax) {
                top: -9%;
                left: calc(57% - 2.1875rem);
            }

            @include max-media(tMax) {
                top: -6%;
                left: calc(54% - 2.1875rem);
                width: 3.25rem;
            }

            @include max-media(mMax) {
                top: rem(-39);
                left: calc(50% + #{rem(14)});
                width: 2.25rem;
            }
        }

        &--big {
            top: 13%;
            right: -16%;
            width: rem(390);
            height: auto;
            left: inherit;
            max-width: rem(390);

            @include max-media(dMax) {
                top: -12%;
            }

            @include max-media(tMax) {
                top: -8%;
                right: -16%;
                width: 18.375rem;
                left: inherit;
                max-width: 18.375rem;
            }

            @include max-media(mMax) {
                top: calc(-3% + #{rem(6)});
                right: -18%;
                width: 8.375rem;
                max-width: 8.375rem;
            }
        }
    }

    &__awards {
        margin-top: rem(-122);
        margin-bottom: rem(307);

        @include max-media(dMax) {
            margin-top: rem(-330);
            margin-bottom: rem(407);
        }

        @include max-media(tMax) {
            margin-top: rem(-426);

            & .swiper-slide {
                @include max-media("mMax") {
                    border-radius: 8px;
                    background-color: $opacity-color-3;
                    height: auto;
                }
            }
        }

        @include max-media(mMax) {
            margin-top: rem(-490);
            margin-bottom: 14.75rem;
        }
    }

    &__thanks {
        position: relative;

        @include max-media(dMax) {
            margin-top: rem(-250);
        }

        @include max-media(tMax) {
            margin-top: rem(-250);
        }
        @include max-media(mMax) {
            margin-top: 0;
            padding: 0;

            & .swiper-slide {
                background-color: $opacity-color-3;
                // padding: rem(16);
                border-radius: 8px;
            }
        }
    }

    &__thanks-planet {
        position: absolute;
        top: rem(-407);
        right: rem(177);
        width: rem(81);
        height: auto;
        z-index: 3;

        @include max-media(dMax) {
            top: rem(-497);
        }
        @include max-media(tMax) {
            top: rem(-407);
            right: rem(188);
        }
        @include max-media(mMax) {
            top: rem(-155);
            width: rem(40);
            right: rem(88);
        }
    }

    &__galactic-right {
        position: absolute;
        top: rem(-400);
        right: rem(-450);
        z-index: 1;
        width: 100%;

        @include max-media(dMax) {
            top: rem(-350);
            right: -24.125rem;
        }
        @include max-media(tMax) {
            top: rem(-334);
            right: rem(-274);
        }

        @include max-media(mMax) {
            top: rem(-230);
            right: rem(-222);
            width: 170%;
        }
    }

    &__action {
        color: $color-1;
        margin-top: rem(92);
        height: rem(500);
        position: relative;

        &::before {
            content: '';
            width: 50%;
            height: rem(600);
            position: absolute;
            top: 35%;
            left: -20%;
            background: radial-gradient(rgba(47, 147, 165, .8) -55%, rgba(0, 53, 255, .1) 45%, rgba(0, 240, 255, 0) 73%);
            @include max-media(mMax) {
                width: 100%;
                height: 100%;
                top: 43%;
                left: -45%;
            }
                       @include min-media(xxxl) {
                width: 100%;
                height: 180%;
                top: 15%;
                left: -53%;
            }
        }

        @include max-media(dMax) {
            margin-top: 0;
            max-width: rem(1024);
        }
        @include max-media(tMax) {
            max-width: rem(768);
        }
        @include max-media(mMax) {
            max-width: rem(375);
        }
        @include max-media(sMax) {
            max-width: rem(320);
        }
    }

    &__action-title {
        text-align: center;
    }
    &__action-wrapper {
        position: relative;
    }
    &__astronaut {
        position: absolute;
        top: rem(-72);
        left: rem(117);
        z-index: 3;
        animation: levitation 6s ease-in-out infinite;

           svg {
            width: 103%;
            height: auto;
        }
        @include max-media(dMax) {
            top: -4.5rem;
            left: 2.3125rem;
        }
        @include max-media(tMax) {
            width: rem(400);
            top: 0.5rem;
            left: 1.3125rem;
        }

        @include max-media(mMax) {
            width: rem(300);
            top: rem(-34);
            left: rem(-71);
        }
    }

    &__cable {
        position: absolute;
        top: rem(200);
        left: 23.125rem;
        width: rem(600);
        z-index: 2;

           svg {
            width: 100%;
            height: auto;
        }
        @include max-media(dMax) {
            top: 10.875rem;
            left: 18.125rem;
            width: rem(420);
        }
        @include max-media(tMax) {
            top: 13.875rem;
            left: 15.125rem;
            width: rem(341);
        }

        @include max-media(mMax) {
            top: rem(189);
            left: rem(53);
            transform: rotate(30deg);
            width: rem(316);
        }
        @include max-media(sMax) {
            width: rem(273);
            left: rem(47);
            transform: rotate(32deg);
        }
    }

    &__hole {
        position: absolute;
        top: rem(6);
        right: rem(27);
        width: rem(577);
        height: rem(410);
        svg {
            width: 100%;
            height: auto;
        }

        @include max-media(dMax) {
            top: -3.625rem;
            right: 0.6875rem;
        }

        @include max-media(tMax) {
            top: -0.625rem;
            right: -7.3125rem;
            width: 36.0625rem;
            height: 25.625rem;
        }

        @include max-media(mMax) {
            top: rem(110);
            right: rem(-173);
            width: rem(372);
            height: rem(410);
        }
    }

    &__intersect {
        position: absolute;
        top: rem(314);
        right: rem(273);
        width: rem(60);
        z-index: 3;
        cursor: pointer;
        svg {
            width: 100%;
            height: auto;
        }

        @include max-media(dMax) {
            top: 15.1875rem;
            right: 15.3125rem;
        }

        @include max-media(tMax) {
            top: 18.1875rem;
            right: 7.3125rem;
        }

        @include max-media(mMax) {
            top: rem(312);
            right: rem(-17);
            width: rem(40);
        }
    }

    &__team-wrapper {
        // background-color: #030102;
        margin-top: 0 !important;
        padding-top: rem(32);
        position: relative;
        z-index: 10;
    }

    &__planet-wrapper {
        height: rem(1200);
        overflow: hidden;
    }

    &__thanks-card-logo {
        width: 100% !important;

        @include max-media("mMax") {
            height: rem(64) !important;
        }
    }
}