@use "/src/styles/_variables" as *;
@use "/src/styles/_function" as *;
@use "/src/styles/_media" as *;
@use "/src/styles/_animations" as *;

$parent: "list-section";

.#{$parent} {
  color: $color-1;
  z-index: 2;

  @include min-media("d") {
    padding: 0 rem(128);
  }

  &__title {
    margin-bottom: rem(32);

    @include min-media("d") {
      margin-bottom: rem(48);
    }
  }

  &__list {
    list-style: none;

    & .swiper-wrapper {
      @include min-media("t") {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: rem(32) rem(32);
      }
    }
    &.swiper {
      overflow: visible;
    }

    &--default {
      display: grid;
      grid-template-columns: 1fr;
      gap: rem(32) 0;

      @include min-media("t") {
        grid-template-columns: 1fr 1fr 1fr;
        gap: rem(32) rem(32);
      }
    }
  }

  &__decor {
    display: none;

    @include min-media("d") {
      width: rem(228);
      height: rem(228);
      display: block;
      position: absolute;
      right: 0;
      bottom: rem(-228);
      object-fit: scale-down;
      object-position: center;
    }
  }

  &--items-between-v2 {
    & .swiper-wrapper {
      @include min-media("d") {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: rem(56) rem(56);
      }
    }
  }

  &--col-4 {
    .#{$parent} {
      &__list {
        & .swiper-wrapper {
          @include min-media("t") {
            grid-template-columns: 1fr 1fr 1fr 1fr;
          }
        }

        &--default {
          @include min-media("t") {
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-gap: rem(40) rem(72);
          }
        }
      }
    
      &--items-between-v2 {
        & .swiper-wrapper {
          @include min-media("d") {
            grid-template-columns: 1fr 1fr 1fr 1fr;
          }
        }
      }
    }
  }

  &--col-2 {
    .#{$parent} {
      &__list {
        & .swiper-wrapper {
          @include min-media("t") {
            grid-template-columns: 1fr 1fr;
          }
        }

        &--default {
          @include min-media("t") {
            grid-template-columns: 1fr 1fr;
            grid-gap: rem(40) rem(72);
          }
        }
      }
    
      &--items-between-v2 {
        & .swiper-wrapper {
          @include min-media("d") {
            grid-template-columns: 1fr 1fr;
          }
        }
      }
    }
  }

  &--col-11 {
    .#{$parent} {
      &__list {
        & .swiper-wrapper {
          @include min-media("t") {
            grid-template-columns: repeat(11, minmax(#{auto, rem(64)}));
            grid-auto-rows: rem(64);
            gap: rem(38) 0;
            justify-content: space-between;
          }
        }

        &--default {
          @include min-media("t") {
            grid-template-columns: repeat(11, 1fr);
            grid-gap: rem(40) rem(72);
          }
        }
      }
    
      &--items-between-v2 {
        & .swiper-wrapper {
          @include min-media("d") {
            grid-template-columns: repeat(11, 1fr);
          }
        }
      }
    }
  }
}
