@use "/src/styles/_variables" as *;
@use "/src/styles/_function" as *;
@use "/src/styles/_media" as *;

.notification-wrapper {
    margin-bottom: rem(16);
}

.notification {
    background-color: $color-1;
    border-radius: rem(12);
    width: rem(344);
    padding: rem(14) rem(22) rem(10) rem(14);
    border: 2px solid transparent;
    color: $color-4;
    animation-name: upTranslate;
    animation-duration: .6s;

    &--error {
        border-color: $color-6;
    }

    @supports (backdrop-filter: blur(18px)) {
        backdrop-filter: blur(18px);
    }

    @include min-media('t') {
        bottom: initial;
    }

    &__text {
        margin-top: rem(4);
    }

    &__close {
        width: rem(17);
        height: rem(17);
        position: absolute;
        right: rem(12);
        top: rem(16);
        z-index: 2;

        &:before,
        &:after {
            content: '';
            width: rem(2);
            height: 100%;
            background: $color-4;
            position: absolute;
            top: 0;
            left: 50%;
            transition: background 0.3s ease;
        }

        &:before {
            transform: translateX(-50%) rotate(-45deg);
        }
        &:after {
            transform: translateX(-50%) rotate(45deg);
        }

        &:hover:before,
        &:hover:after{
            background: $color-4;
        }
    }

    &__button {
        border: 1px solid $color-4;
        margin-top: rem(16);
        width: 100%;
        height: rem(40)
    }
}

@keyframes upTranslate {
    0% {
        transform: translateY(100px);
        opacity: 0;
    }
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}
