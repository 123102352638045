@use "/src/styles/_variables" as *;
@use "/src/styles/_function" as *;
@use "/src/styles/_media" as *;

$parent: "card-logo";

.#{$parent} {
  height: rem(64);
  width: rem(64);

  &__image-wrapper {
    width: 100%;
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    min-height: rem(60);
    display: block;
  }

  &__description {
    margin-top: rem(16);
    color: $color-8;
    text-align: center;

    @include max-media(mMax) {
      margin-top: rem(10);
    }
  }

  &--unresized {
    width: 100%;
    height: auto;
  }
}
