@use "/src/styles/_variables" as *;
@use "/src/styles/_function" as *;
@use "/src/styles/_media" as *;

.heading {
    @include min-media('t') {
        display: grid;
        grid-gap: rem(16);
        grid-template-columns: minmax(auto, 1.2fr) 1fr;
    }

    @include min-media('d') {
        grid-template-columns: minmax(auto, 1.8fr) 1fr;
        
    }

    &__title {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    &__text {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        justify-content: space-around;

        margin-top: rem(8);

        @include min-media('t') {
            margin-top: 0;
        }
    }

    &__link {
        @include min-media('t') {
            align-self: flex-end;
            margin-bottom: 0;
        }
    }

    &__description + &__link {
        align-self: flex-start;
        margin-top: rem(16);
    }

    &__link,
    &__unit {
        margin-top: auto;
    }

    &__unit {
        display: flex;
        align-items: center;
        margin-top: rem(8);
        margin-bottom: rem(16);

        @include min-media('d') {
            margin-top: rem(14);
            margin-bottom: 0;
        }

        span + span {
            position: relative;
            margin-left: rem(10);

            &:before {
                content: '';
                position: absolute;
                left: rem(-6);
                width: rem(2);
                height: rem(2);
                background-color: $color-1;
                border-radius: 50%;
                top: calc(50% + #{rem(2)});
                transform: translateY(-50%);
            }
        }
    }

    &__description {
        @include min-media('t') {
            margin-top: rem(10);
        }
    }

    &--light {
        color: $color-1;

        .heading__link.link--with-bg {
            @include min-media('t') {
                padding: 0 rem(40);
                margin-bottom: rem(10);
            }
        }
    }
}
