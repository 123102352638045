@use "/src/styles/_variables" as *;
@use "/src/styles/_function" as *;
@use "/src/styles/_media" as *;

$parent: "detail-works";

.#{$parent} {
  padding-top: rem(32);
  color: $color-1;
  padding-bottom: rem(32);
  position: relative;
  min-height: 100vh;

  @include min-media("t") {
    padding-top: rem($header_s + 32);
    padding-bottom: rem(49);
  }

  @include min-media("d") {
    padding: rem($header + 46) 0 rem(40);
  }

  &-container {
    position: relative;
  }

  & &__description, & &__reviews, & &__feedback, & &__projects {
    padding-left: 0;
    padding-right: 0;
  }

  &__heading {
    @include min-media('d') {
      grid-template-columns: 1fr 1fr;
    }

    &.container {
      padding-bottom: rem(32);

      @include min-media('t') {
          padding: 0 rem(32) rem(32);
      }

      @include min-media('d') {
          padding: 0 rem(99) rem(56);
      }
    }

    & > * {
      @include min-media('d') {
        justify-content: flex-start;
      }

      &:last-child > * {
        &:nth-child(2) {
          margin-top: rem(8);

          @include min-media('d') {
            margin-top: rem(20);
          }
        }
      }
    }

    &.#{$parent}__text {
      & > *:last-child > * {
        &:nth-child(1) {
          @include min-media('d') {
            margin-top: 0;
          }
        }
      }
    }
  }

  &__description,
  &__projects,
  &__reviews {
    &.container {
      @include min-media('t') {
          padding: 0 rem(32);
      }

      @include min-media('d') {
          padding: 0 rem(64);
      }
    }
  }

  &__media {
    border-radius: rem(12);
    overflow: hidden;

    & + & {
      margin-top: rem(8);

      @include min-media('d') {
        margin-top: rem(16);
      }
    }
  }

  &__text {
    margin: rem(32) 0;

    @include min-media('d') {
      padding: 0 rem(64);
      margin: rem(65) 0 rem(78);
    }
  }

  &__reviews {
    @include min-media('t') {
      padding: 0 rem(32);
    }

    @include min-media("md") {
      max-width: rem(816);
      box-sizing: content-box;
      text-align: center;
      padding: 0;
    }
  }

  &__title {
    margin-bottom: rem(28);

    @include min-media("d") {
      margin-bottom: rem(48);
    }
  }

  &__projects-list {
    margin: rem(-8) 0;

    @include min-media("t") {
      display: flex;
      justify-content: center;
      margin: 0 rem(-8);
    }

    & > * {
      display: block;
      margin: rem(8) 0;
      height: rem(358);

      @include min-media("t") {
        height: rem(342);
        width: calc(50% - #{rem(16)});
        margin: 0 rem(8);
      }
    }
  }

  section + section {
    @include max-media('mMax') {
      margin-top: rem(32);
    }
  }

  &__design {
    position: absolute;
    z-index: 1;
    width: rem(186);
    height: rem(186);
    display: none;

    @include min-media("md") {
      display: block;
    }

    &--tr {
      top: rem(508);
      right: rem(-50);
    }

    &--tl {
      top: rem(1460);
      left: rem(-50);
    }

    &--mr {
      top: rem(2989);
      left: rem(-50);
      width: rem(256);
      height: rem(256);
    }

    &--ml {
      top: rem(4400);
      right: rem(-50);
      width: rem(222);
      height: rem(222);
    }
  }

  &__feedback {
    &.container {
      @include min-media('t') {
        padding: 0 rem(32);
      }

      @include min-media('d') {
        padding: 0 rem(64);
      }
    }
  }

  &__html-content {
    min-height: 100vh;
    margin: 0 auto;
    margin-bottom: rem(88);

    @include min-media("t") {
      & > * {
        opacity: 0;
      }
    }

    @include min-media("d") {
      padding: 0 rem(64);
      margin-bottom: rem(112);
    }

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      border-radius: rem(12);
      margin-bottom: rem(8);

      @include min-media("d") {
        margin-bottom: rem(16);
      }

      & + p,
      & + dl {
        margin: rem(24) 0 rem(32);

        @include min-media("d") {
          margin: rem(56) 0 rem(72);
        }
      }
    }

    video {
      width: 100%;
      height: auto;
      border-radius: rem(12);
      margin-bottom: rem(8);
      object-fit: cover;

      @include min-media("d") {
        margin-bottom: rem(16);
      }

      & + p,
      & + dl {
        margin: rem(24) 0 rem(32);

        @include min-media("d") {
          margin: rem(56) 0 rem(72);
        }
      }
    }

    p, dl {
      margin: rem(32) 0;
      padding: 0;

      @include min-media("d") {
        margin: rem(72) 0;
        padding: 0 rem(64);
      }
    }

    p {
      font-size: rem(24);
      line-height: rem(32);
    }

    dl {
      display: flex;
      flex-direction: column;

      dt {
        font-size: rem(24);
        line-height: rem(32);
        font-weight: bold;
        margin-bottom: rem(16);
      }

      dd {
        font-size: rem(22);
        line-height: rem(32);
        margin-left: 0;
      }

      @include min-media("d") {
        flex-direction: row;
        column-gap: rem(16);

        dt {
          width: 50%;
          font-size: rem(40);
          line-height: rem(48);
        }

        dd {
          width: 50%;
          font-size: rem(24);
        }
      }
    }
  }
}
