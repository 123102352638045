@use "/src/styles/_variables" as *;
@use "/src/styles/_function" as *;
@use "/src/styles/_media" as *;

.main {
    min-height: 100vh;
    background-image: linear-gradient(to bottom, #01152E 20%, #010512 50%, #081021 100%);
    position: relative;

    &__heading {
        @include max-media('mMax') {
            text-align: center;
        }

        & > * {
            @include max-media('mMax') {
                align-items: center;
            }
        }
    }

    & > *:nth-child(2) {
        position: relative;
        min-height: rem(356);

        &::before {
            content: '';
            position: absolute;
            top: 0;
            width: 100vw;
            left: calc((100vw - 100%) / -2);
            height: 100%;
            background: linear-gradient(90deg, #1C69A9 6.57%, #860F4F 96.36%), linear-gradient(180deg, rgba(28, 105, 169, 0) 0%, #305898 20.84%, #860F4F 75.37%, rgba(134, 15, 79, 0) 100%), linear-gradient(to bottom, #000, transparent);
            opacity: 0.3;
            z-index: 1;
            filter: blur(60px);
        }

        &::after {
            content: '';
            width: 100%;
            min-height: rem(1533);
            background-image: linear-gradient(to bottom, transparent, black 35%, black 70%, transparent);
            position: absolute;
            left: 0;
            top: 100%;
            z-index: 1;
        }
    }

    & > *:nth-child(3) {
        &:before {
            content: '';
            position: absolute;
            top: rem(-200);
            width: 100vw;
            left: calc((100vw - 100%) / -2);
            height: rem(1533);
            max-height: 100%;
            background-image: linear-gradient(to bottom, rgba(3, 1, 2, 0) 0%, #030102 16.15%, #030102 73.19%,rgba(3, 1, 2, 0) 100%);
            z-index: -1;
        }
    }

    & > *:nth-child(4) {
        @include min-media("d") {
            padding-top: rem(305);
        }
    }

    &__design {
        margin: 0;
        z-index: 0;
    }

    &__slider {
        &.slider {
            @include min-media("d") {
                padding-top: rem(54);
            }
        }
    }

    &__gradient {
        &:nth-child(1) {
            top: rem(-2500);
            right: 0;

            @include min-media('d') {
                right: rem(-600);
            }
        }
        &:nth-child(2) {
            top: rem(-700);
            left: 0;

            @include min-media('d') {
                top: rem(-1000);
                left: rem(-600);
            }
        }
        &:nth-child(3) {
            top: 0;
            right: 0;

            @include min-media('d') {
                top: rem(-1000);
                right: rem(-600);
            }
        }
        &:nth-child(4) {
            top: rem(-2200);
            left: 0;

            @include min-media('d') {
                left: rem(-600);
            }
        }
    }
}
