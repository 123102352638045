@use "/src/styles/_variables" as *;
@use "/src/styles/_function" as *;
@use "/src/styles/_media" as *;

$parent: "detail-service";

.#{$parent} {
  min-height: calc(100vh - 321px);
  padding: rem(32) 0 0;
  background: radial-gradient(rgba(#900E54, 0.5) 1%, rgba(#900E54, 0) 70%) no-repeat left -20vw top -20vw,
              radial-gradient(rgba(#900E54, 0.5) 1%, rgba(#900E54, 0) 70%) no-repeat right -20vw top -20vw,
              radial-gradient(rgba(#0066FF, 0.5) 1%, rgba(#900E54, 0) 70%) no-repeat bottom -30vw right -20vw;
  background-size: 50vw 50vw, 50vw 50vw, 50vw 50vw;
  background-attachment: fixed;

  @include min-media("t") {
    padding-top: rem($header_s + 32);
    min-height: calc(100vh - 161px);
  }

  @include min-media("d") {
    padding: rem($header + 24) 0 0;
    min-height: calc(100vh - 88px);
  }

  &__section-line {
    background-color: $color-1;
    height: 2px;
    width: calc(100% - rem(32));
    opacity: 0.2;
    margin-bottom: rem(36);

    @include min-media("d") {
      max-width: rem(1024);
      padding: 0 rem(128);
      margin-bottom: rem(72);
    }
  }

  &__list-with-icon,
  &__list-three-x,
  &__banner {
    margin-bottom: rem(40);

    @include min-media("d") {
      margin-bottom: rem(72);
    }
  }

  &__section-video {
    margin: rem(60) auto;

    @include min-media("d") {
      margin: rem(120) auto;
    }
  }

  &__section-with-button {
    margin-bottom: rem(56);

    @include min-media("d") {
      margin-bottom: rem(104);
    }
  }

  &__marked-list {
    margin-bottom: rem(56);

    @include min-media("d") {
      margin-bottom: rem(96);
    }
  }

  &__list-two-x {
    margin-bottom: rem(68);

    @include min-media("d") {
      margin-bottom: rem(48);
    }
  }

  &__reviews {
    margin-bottom: rem(108);

    @include min-media("d") {
      margin-bottom: rem(194);
    }
  }

  &__button-text-photo {
    margin-bottom: rem(48);

    @include min-media("d") {
      margin-bottom: rem(104);
    }
  }

  &__section-text {
    margin-bottom: rem((40));

    @include min-media("d") {
      margin-bottom: rem(104);
    }
  }

  &__section-with-logo {
    margin-bottom: rem(120);
  }

  &__events {
    margin-bottom: rem(56);

    @include min-media("d") {
      margin-bottom: rem(80);
    }
  }

  &__list-n-x {
    margin-bottom: rem(24);
    
    & .swiper-slide {
      background-color: $opacity-color-3;
      border-radius: 8px;
    }

    @include min-media("t") {
      & .swiper-slide {
        background-color: inherit;
        border-radius: 0;
      }
    }

    @include min-media("d") {
      margin-bottom: rem(96);
    }
  }

  &__feedback {
    padding-bottom: rem(32);
    
    @include min-media("d") {
      padding: rem(64);
    }
  }
}