@use "/src/styles/_variables" as *;
@use "/src/styles/_function" as *;
@use "/src/styles/_media" as *;

.card-release {
    position: relative;
    border-radius: rem(12);
    color: $color-1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    transform-origin: center;

    iframe {
        border-radius: rem(12);
    }

    iframe,
    &__image img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }


    video {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    &__wrapper {
        position: relative;
        z-index: 2;
        margin-top: auto;
        background: linear-gradient(180deg, rgba(36, 155, 169, 0) 0%, rgba(37, 159, 173, 0.9) 50%);
        padding: rem(16) rem(24);

        @include min-media('t') {
            padding: rem(8) rem(16);
        }
    
        @include min-media('d') {
            padding: rem(16) rem(24);
        }
    }

    &__label {
        font-weight: 700;
        position: relative;
        display: block;
        margin-top: auto;
        margin-bottom: rem(8);
        z-index: 1;

        @include min-media('t') {
            margin-bottom: rem(4);
        }

        @include min-media('d') {
            // padding: 0 rem(32);
            margin-bottom: rem(8);
        }
    }

    &__text {
        position: relative;
        display: block;
        z-index: 1;
    }

    &__item-wrapper {
        display: block;
        width: 100%;
        height: 100%;
    }

    &--video {
        aspect-ratio: 16/9;
    }
}
