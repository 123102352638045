@use "/src/styles/_variables" as *;
@use "/src/styles/_function" as *;
@use "/src/styles/_media" as *;

$parent: "static-page";

.#{$parent} {
  position: relative;
  padding-top: rem(24);
  color: $color-1;

  @include min-media("t") {
    padding-top: rem($header_s + 20);
    padding-bottom: rem(49);
  }

  &__content {
    color: $color-1;
    position: relative;

    @include min-media("d") {
      padding: 0 rem(64);

      & > *:not(.swiper, iframe) {
        max-width: rem(816);
        margin-left: auto;
        margin-right: auto;
      }
    }

    h1 {
      font-weight: 700;
      font-size: rem(32);
      line-height: rem(40);
      @include min-media("t") {
        font-size: rem(53);
        line-height: rem(64);
      }
    }

    h2 {
      font-weight: 700;
      font-size: rem(24);
      line-height: rem(32);
      margin-bottom: rem(8);
      @include min-media("t") {
        font-size: rem(48);
        line-height: rem(56);
        margin-bottom: rem(16);
      }
    }

    h2 + p {
      font-weight: 400;
      font-size: rem(22);
      line-height: rem(32);
      @include min-media("t") {
        font-size: rem(24);
        line-height: rem(32);
        margin-bottom: rem(18);
      }
    }

    h3 {
      font-weight: 700;
      font-size: rem(24);
      line-height: rem(32);
      margin-bottom: rem(8);
      @include min-media("t") {
        font-size: rem(40);
        line-height: rem(48);
        margin-bottom: rem(24);
        width: 83%;
      }
    }
    h3 + p {
      font-weight: 400;
      margin-bottom: rem(8);
      @include min-media("t") {
        margin-bottom: rem(24);
      }
    }

    h4 {
      font-weight: 700;
      font-size: rem(24);
      line-height: rem(24);
      margin-bottom: rem(16);
      @include min-media("t") {
        font-size: rem(32);
        line-height: rem(40);
      }
    }

    h5 {font-weight: 700;
      font-size: rem(18);
      line-height: rem(24);
      margin-bottom: rem(16);
      @include min-media("t") {
        font-weight: 700;
        font-size: rem(18);
        line-height: rem(24);
      }
    }

    p {
      font-weight: 400;
      font-size: rem(16);
      line-height: rem(24);
      margin-bottom: rem(24);

    }
    p + ol {
      @include min-media("t") {
        margin-top: rem(8);
      }
    }

    li {
      font-weight: 400;
      font-size: rem(16);
      line-height: rem(24);
    }
    li:not(:last-child) {
      margin-bottom: rem(16);
      @include min-media("t") {
        margin-bottom: rem(24);
      }
    }

    li > ul {
      @include min-media("t") {
        margin-bottom: rem(8);
      }
    }

    & > ol {
      @include min-media("t") {
        margin-top: rem(40);
      }

      & > li {
        counter-increment: li;

        span,
        &::marker {
          font-weight: 700;
          font-size: rem(24);
          line-height: rem(24);
          margin-bottom: rem(16);
          @include min-media("t") {
            font-size: rem(32);
            line-height: rem(40);
          }
        }

        & > ol {
          margin-top: rem(16);

          @include min-media("t") {
            margin-bottom: rem(8);
          }
  
          & > li {
            &::marker {
              content: counter(li) "." counter(list-item) ". ";
            }
          }
        }
      }
    }

    ul > li:not(:last-child) {
      @include min-media("t") {
        margin-bottom: rem(16);
      }
    }
    ul {
      @include min-media("t") {
        margin-bottom: rem(24);
      }

      @include min-media("d") { 
        padding-left: rem(32);
      }
    }
    blockquote {
      position: relative;
      font-weight: 400;
      font-size: rem(24);
      line-height: rem(32);
      margin: rem(24) 0 rem(40) 0;
      padding-left: rem(32);
      background: url($quotes-icon) no-repeat left top;
      background-size: rem(24);

      @include min-media("t") {
        margin-bottom: rem(80);
      }
    }
    table {
      border-collapse: collapse;
      width: rem(816);
      margin: 0 auto;
    }
    th {
      width: rem(204);
      border-bottom: rem(1) solid  $color-16;
      font-weight: 400;
      font-size: rem(16);
      line-height: rem(24);
      text-align: left;
      padding: rem(12) rem(8);

    }
    td {
      width: rem(204);
      border-bottom: rem(1) solid  rgba(255, 255, 255, .2);
      font-weight: 400;
      font-size: rem(16);
      line-height: rem(24);
      color: $color-12;
      padding: rem(12) rem(8);

    }

    ol {
      margin-bottom: rem(48);
      @include min-media("d") {
        padding-left: rem(24);
      }
    }
  }

  &__gradient--tl {
    display: block;
    top: rem(-400);
    left: rem(-400);

    @include max-media('dMax') {
        width: rem(900) !important;
        height: rem(900) !important;
    }

    @include min-media('xl') {
        top: rem(-897);
        left: rem(-494);
    }

  }
  &__gradient--tr {
    top: rem(-400);
    right: rem(-400);
    left: auto;

    @include max-media('mMax') {
      top: rem(800);
    }

    @include min-media('xl') {
        top: rem(-657);
        right: rem(-718);
    }
  }
}