@use "/src/styles/_function" as *;
@use "/src/styles/_media" as *;
@use "/src/styles/_variables" as *;

.card-grid-1 {
    display: grid;
    grid-auto-columns:  1fr;
    grid-auto-rows: rem(358);
    row-gap: rem(8);

    @include min-media('t') {
        grid-auto-columns: 28.2% 28.1% 39.5%;
        grid-auto-rows: minmax(rem(348), max-content) minmax(rem(283), max-content) minmax(rem(190), max-content);
        grid-gap: rem(24);

        & > * {
            // 1
            &:nth-child(5n - 4) {
                grid-column: 1 / 4;
                grid-row: span 1;
            }

            //2
            &:nth-child(5n - 3) {
                grid-column: 1 / 3;
                grid-row: span 1;
            }

            //3
            &:nth-child(5n - 2) {
                grid-column: 3 / 4;
                grid-row: span 2;
            }

            //4
            &:nth-child(5n - 1) {
                grid-column: 1 / 2;
                grid-row: span 1;
            }

            //5
            &:nth-child(5n) {
                grid-column: 2 / 3;
                grid-row: span 1;
            }
        }
    }

    @include min-media('md') {
        grid-auto-rows: minmax(rem(464), max-content) minmax(rem(464), max-content) minmax(rem(312), max-content);
    }
}

.card-grid-2 {
    display: grid;
    grid-auto-columns:  1fr;
    grid-auto-rows: rem(358);
    row-gap: rem(8);

    @include min-media('t') {
        grid-auto-columns: 39.5% 27.1% 27.3%;
        grid-auto-rows: minmax(rem(283), max-content) minmax(rem(190), max-content);
        grid-gap: rem(24);

        & > * {
            // 1
            &:nth-child(4n - 3) {
                grid-column: 1 / 2;
                grid-row: span 2;
            }

            //2
            &:nth-child(4n - 2) {
                grid-column: 2 / 4;
                grid-row: span 1;
            }

            //3
            &:nth-child(4n - 1) {
                grid-column: 2 / 3;
                grid-row: span 1;
            }

            //4
            &:nth-child(4n) {
                grid-column: 3 / 4;
                grid-row: span 1;
            }
        }
    }

    @include min-media('md') {
        grid-auto-rows: minmax(rem(400), max-content) minmax(rem(376), max-content);
    }
}

.card-grid-3 {
    display: grid;
    grid-auto-columns:  1fr;
    grid-auto-rows: rem(358);
    row-gap: rem(8);

    @include min-media('t') {
        grid-auto-columns: 28.2% 28.1% 39.5%;
        grid-auto-rows: minmax(rem(283), max-content) minmax(rem(190), max-content);
        grid-gap: rem(24);

        & > * {
            // 1
            &:nth-child(4n - 3) {
                grid-column: 1 / 3;
                grid-row: span 1;
            }

            //2
            &:nth-child(4n - 2) {
                grid-column: 3 / 4;
                grid-row: span 2;
            }

            //3
            &:nth-child(4n - 1) {
                grid-column: 1 / 2;
                grid-row: span 1;
            }

            //4
            &:nth-child(4n) {
                grid-column: 2 / 3;
                grid-row: span 1;
            }
        }
    }

    @include min-media('md') {
        grid-auto-rows: minmax(rem(464), max-content) minmax(rem(312), max-content);
    }
}
