@use "/src/styles/_variables" as *;
@use "/src/styles/_function" as *;
@use "/src/styles/_media" as *;

.image {
    width: 100%;
    display: block;

    img {
        width: 100%;
        display: block;
        transition: .3s linear opacity;
    }

    &--show-placeholder {
        &::after {
            position: absolute;
            top: 0;
            inset-inline-end: -150%;
            bottom: 0;
            inset-inline-start: -150%;
            background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%,  rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0.7) 53.03%,  rgba(255, 255, 255, 0) 85%, rgba(255, 255, 255, 0) 100%);
            background-color: $opacity-color-3;
            animation-name: placeholderMove;
            animation-duration: 1.4s;
            animation-timing-function: ease;
            animation-iteration-count: infinite;
            opacity: 0.3;
            content: ""
        }

        img {
            opacity: 0;
        }
    }
}

@keyframes placeholderMove {
    0% {
        transform: translateX(-37.5%)
    }

    100% {
        transform: translateX(37.5%)
    }
}
