@use "/src/styles/_variables" as *;
@use "/src/styles/_function" as *;
@use "/src/styles/_media" as *;

.introduction {
    // height: rem(462);
    height: rem(427);
    position: relative;

    @include min-media('t') {
        height: rem(793);
    }

    @include min-media('d') {
        height: rem(544);
    }

    @include min-media('xxl') {
        height: rem(800);
    }

    & + section {
        margin-top: 0;
    }

    &__logo {
        position: absolute;
        top: rem(24);
        left: 50%;
        transform: translateX(-50%);
        width: rem(188);
        z-index: 1;

        @include min-media('t') {
            display: none;
        }
    }

    &__coalla {
        -webkit-transition: transform;
        position: absolute;
        // top: rem(130);
        top: rem(85);
        left: 50%;
        transform: translateX(-50%);
        z-index: 3;
        width: 135%;
        max-width: rem(570);
        pointer-events: none;

        @include min-media('t') {
            width: 108%;
            top: rem(150);
            max-width: rem(800);
        }

        @include min-media('d') {
            max-width: initial;
            width: 100%;
            height: rem(500);
            top: rem(120);
        }

        @include min-media('xxl') {
            width: rem(1035);
            height: rem(676);
            top: rem(152);
        }

        & > * {
            width: 100%;
            height: 100%;
        }
    }

    &__planet {
        margin-top: 80%;
        width: 100%;
        height: auto;
        margin-left: 50%;
        pointer-events: none;
        transform: translateX(-50%);
        position: relative;

        @include min-media('t') {
            margin-top: rem(520);
            width: 200%;
            margin-left: 50%;
            transform: translateX(-50%);
        }

        @include min-media('d') {
            width: initial;
            transform: translateX(-50%);
            margin-top: rem(565);
            margin-left: 50%;
        }

        &--second {
            margin-top: 0;
            top: 0;
            width: 100%;
            height: auto;
            margin-left: 50%;
            pointer-events: none;
            transform: translateX(-50%) translateY(-70%);
            clip-path: polygon(0% 30%, 100% 30%, 100% 100%, 0% 100%);

            @include min-media('t') {
                width: 200%;
            }

            @include min-media('d') {
                width: initial;
            }

            @include min-media('xxl') {
                transform: translateX(-50%) translateY(-60%);
            }
        }
    }
    &__cube {
        transition: transform .3s linear;
        -webkit-transition: transform;
        animation-fill-mode: backwards;
        -animation-direction: normal;
        -webkit-animation-fill-mode: backwards;
        -webkit-animation-direction: normal;
    }

    &__planet-bg {
        position: absolute;
        width: rem(193);
        height: rem(193);
        left: calc(50% + #{rem(8)});
        top: rem(83);
        transform: translateX(-50%);
        display: block;

        @include min-media('t') {
            left: calc(50% + #{rem(12)});
            width: rem(345);
            height: rem(345);
            top: calc(#{rem($header)} + #{rem(60)});
        }

        @include min-media('d') {
            left: calc(50% + #{rem(12)});
            width: rem(309);
            height: rem(309);
            top: calc(#{rem($header)} + #{rem(11)});
        }

        @include min-media('xl') {
            left: calc(50% + #{rem(15)});
            width: rem(432);
            height: rem(430);
            top: rem(150);
        }
    }

    &__gift {
        pointer-events: all;
        cursor: pointer;
    }

    &__ufo {
        opacity: 0;
    }

    &__line {
        width: rem(2);
        height: rem(80);
        background: linear-gradient(to bottom, $color-1, rgba($color-1, 0));
    }

    &__design {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        transform: scale(0.5);

        @include min-media('t') {
            transform: scale(0.7);
        }

        @include min-media('d') {
            transform: scale(1);
        }
    }

    &__gradient {
        left: auto;
        position: absolute;
        opacity: 1;

        &--red {
            right: 0;
            top: rem(50);

            @include min-media('t') {
                right: rem(-500);
                bottom: rem(-250);
                top: auto;
            }

            // @include min-media('d') {
            //     right: rem(100);
            //     bottom: rem(-250);
            //     top: auto;
            // }

            @include min-media('d') {
                top: 0;
                right: rem(-100);
                bottom: auto;
            }
        }

        &--light-blue {
            left: calc(50% - #{rem(500)});
            top: rem(-500);
            // opacity: 0.5;

            @include min-media('t') {
                top: rem(-500);
                left: calc(50% - #{rem(500)});
            }

            @include min-media('d') {
                top: rem(-600);
                left: calc(50% - #{rem(500)});
            }

            @include min-media('xl') {
                top: rem(-700);
                left: calc(50% - #{rem(700)});
            }
        }

        &--blue {
            left: 0;
            top: rem(50);
            background-image: radial-gradient(rgba(#1E73B9, 0.3) 1%, rgba(#1E73B9, 0) 50%);

            @include min-media('t') {
                left: rem(-600);
                bottom: rem(-250);
                top: auto;
            }

            // @include min-media('d') {
            //     left: rem(100);
            //     bottom: rem(-250);
            //     top: auto;
            // }

            @include min-media('d') {
                background-image: radial-gradient(rgba(#1E73B9, 0.5) 1%, rgba(#1E73B9, 0) 50%);
                left: rem(-100);
                top: 0;
                bottom: auto;
            }
        }

        &--purple {
            background-image: radial-gradient(rgba(#411745, 0.5) 20%, rgba(#411745, 0) 60%);
            position: absolute;
            width: rem(193);
            height: rem(193);
            left: calc(50% + #{rem(8)});
            top: rem(83);
            transform: translateX(-50%);
            display: block;

            @include min-media('t') {
                left: calc(50% + #{rem(12)});
                width: rem(345);
                height: rem(345);
                top: calc(#{rem($header)} + #{rem(60)});
            }

            @include min-media('d') {
                left: calc(50% + #{rem(12)});
                width: rem(309);
                height: rem(309);
                top: calc(#{rem($header)} + #{rem(11)});
            }

            @include min-media('xl') {
                background-image: radial-gradient(rgba(#411745, 0.5) 40%, rgba(#411745, 0) 60%);
                left: calc(50% + #{rem(5)});
                width: rem(600);
                height: rem(600);
                top: rem(150);
            }
        }
    }

    &__table,
    &__text-block {
        @include max-media('mMax') {
            display: none;
        }
    }
}
