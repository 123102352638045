@use "/src/styles/_variables" as *;
@use "/src/styles/_function" as *;
@use "/src/styles/_media" as *;

$parent: popup;

.#{$parent} {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.5);
    text-align: center;
    white-space: nowrap;
    z-index: 1000;
    
    &::after {
        display: inline-block;
        vertical-align: middle;
        width: 0;
        height: 100%;
        content: '';
    }
    video {
        display: none;
        @include min-media('t') {
            display: block;
            position: absolute;
            transform: translateX(-50%);
            left: 50%;
            height: 100%;
        }
    }

    &__button {
        width: rem(17);
        height: rem(17);
        position: absolute;
        right: rem(30);
        top: rem(30);
        z-index: 2;

        &:before,
        &:after {
            content: '';
            width: rem(2);
            height: 100%;
            background: $color-4;
            position: absolute;
            top: 0;
            left: 50%;
            transition: background 0.3s ease;
        }

        &:before {
            transform: translateX(-50%) rotate(-45deg);
        }
        &:after {
            transform: translateX(-50%) rotate(45deg);
        }

        &:hover:before,
        &:hover:after{
            background: $color-4;
        }
    }

    &__overlay {
        opacity: 0;
        display: inline-block;
        vertical-align: middle;
        width: 100%;

        @include max-media('tMax') {
            height: 100vh;
            height: calc(var(--vh, 1vh) * 100);
            background-color: $color-4;
        }

        @include min-media('md') {
            max-width: rem(942);
        }
    }

    &__wrapper {
        box-shadow: 0px 0px 12px rgba(126, 139, 175, 0.65);
        padding: rem(50) rem(20);
        width: 100%;
        text-align: left;
        white-space: normal;
        background-color: $color-1;
        color: $color-7;
        position: relative;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        max-height: 100vh;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }

        @include max-media('tMax') {
            min-height: 100%;
        }

        @include min-media('t') {
            padding: rem(90) rem(60);
        }

        @include min-media('d') {
            padding: rem(90) rem(90) rem(109);
        }
    }

    &__title {
        margin-bottom: rem(30);
    }

    &__link {
        margin-top: rem(30);
    }

    &__heading {
        margin-bottom: rem(30);
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &__controls {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin: rem(10) 0 rem(-10); 

        @include min-media('t') {
            margin: rem(20) rem(-4) 0; 
            flex-direction: row;
        }

        & > * {
            height: rem(48);
            font-size: rem(17);
            flex-direction: column;
            width: 100%;
            margin: rem(10) 0;

            @include min-media('t') {
                width: rem(166);
                margin: 0 rem(4);
            }
        }
    }

    &--gift {
        .#{$parent}__overlay {
            @include min-media('t') {
                height: initial;
                max-width: rem(528);
            }

            @include min-media('d') {
                max-width: rem(528);
            }
        }

        .#{$parent}__wrapper {
            box-shadow: none;
            background: linear-gradient(110.39deg, #0BD0FF 3.15%, #7F4EF7 57.15%);
            border-radius: 0;
            color: $color-1;
            overflow: visible;

            @include min-media('t') {
                border-radius: rem(12);
            }

            @include min-media('d') {
                padding: rem(64) rem(64) rem(48);
            }
        }

        .#{$parent}__button {
            &:before,
            &:after {
                background-color: $color-1;
            }
        }

        .#{$parent}__gift {
            margin-left: 50%;
            transform: translateX(-50%);

            @include min-media('t') {
                margin-left: initial;
                position: absolute;
                top: rem(-100);
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }

    &__gift-design {
        & > image {
            transform-origin: -50px 50px;
        }
    }
    
    &__anim {
        position: absolute;
        z-index: 3;
        top: rem(-100);
        left: 50%;
    }

    &--comment {
        .#{$parent}__heading-review {
            display: flex;
            align-items: center;
            @include max-media('mMax') {
                flex-direction: column;
                align-items: inherit;
            }
        }
        .#{$parent}__logo {
            width: rem(64);
            height: rem(64);
            border-radius: 50%;
            overflow: hidden;
            object-fit: cover;
            flex-shrink: 0;
            order: 1;
            order: initial;
            
            &:first-child {
                z-index: 1;
            }
            &:nth-child(2) {
                margin-left: rem(-15);
            }

        }
        .#{$parent}__wrapper-review {
            margin-right: auto;
            order: 0;

            @include min-media('t') {
                margin-left: rem(16);
                margin-right: 0;
                order: initial;
            }
            
        }
        .#{$parent}__description {
            color: rgba($color-1, 0.5);
            display: block;
        }
        .#{$parent}__text {
            margin-top: rem(24);
        }
        .#{$parent}__button--review-close {
            margin-top: rem(16);
            width: 100%;
            height: 100%;
            position: relative;
            top: inherit;
            right: inherit;
            font-weight: 400;
            font-size: rem(16);
            line-height: rem(24);
            color: $color-1;
            padding: rem(8) rem(110);
            border: rem(1) solid $color-1;
            border-radius: rem(53);
            &:before, &::after {
                display: none;
            }
            @include min-media('md') {
                display: none;
            }
        }

        .#{$parent}__overlay {
            text-align: center;
            margin: 0 auto;

            @include min-media('d') {
                max-width: rem(816);
            }
        }

        .#{$parent}__wrapper {
            background: linear-gradient(0deg, #020613, #020613), linear-gradient(110.39deg, #0BD0FF 3.15%, #7F4EF7 57.15%);
            border-radius: 0;
            color: $color-1;
            padding-bottom: rem($header_s + 16);

            @include min-media('t') {
                border-radius: rem(12);
                padding: rem(32) rem(48) rem(48);
            }
        
        }
        .#{$parent}__button {
            &:before,
            &:after {
                background-color: $color-1;
            }
        }
        .#{$parent}__image-anim {
            display: none;
            position: absolute;
            z-index: 3;
            transform: scale(-1, 1) translateY(0) translateX(0) rotate(0);
            bottom: rem(-100);
            left: rem(-160);

            @include min-media('d') {
                display: block;
                pointer-events: none;
                opacity: 0;
            }
        }
    }

    &--form {
        .#{$parent}__wrapper {
            padding: 0;
            background: linear-gradient(0deg, #020613, #020613), linear-gradient(110.39deg, #0BD0FF 3.15%, #7F4EF7 57.15%);
            @include min-media('t') {
             border-radius: rem(12);
            }
        }
        .#{$parent}__overlay {

            @include min-media('t') {
                width: rem(528);
            }
            @include max-media('tMax') {
                height: 100%;
            }
            @media screen and (max-height: 775px) {
                height: 100%;
            }
        }
        .#{$parent}__button:before, .#{$parent}__button:after {
            background: $color-1;
        }
        .#{$parent}__image-anim {
            display: none;
            position: absolute;
            z-index: 3;
            transform: scale(-1, 1) translateY(0) translateX(0) rotate(0);
            bottom: rem(-100);
            left: rem(-150);

            @include min-media('d') {
                display: block;
                pointer-events: none;
                opacity: 0;
            }
        }
        .#{$parent}__image {
            position: relative;
            left: 50%;
            transform: scale(-1, 1) translateX(90%);
            @include min-media('d') {
                display: none;
            }
        }
        .feedback {
            padding: rem(40) rem(16);
            border-radius: rem(12);
            background: linear-gradient(0deg, #020613, #020613), linear-gradient(110.39deg, #0BD0FF 3.15%, #7F4EF7 57.15%);
            @include min-media('t') {
                padding: rem(64) 0;
            }
            a {
                color: $color-5;
            }
        }
    }
}
