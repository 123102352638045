@font-face {
	font-family: 'Journal Sans New';
	src: url('../assets/fonts/JournalSansNew.woff2') format('woff2'),
		url('../assets/fonts/JournalSansNew.woff') format('woff');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Journal Sans New';
	src: url('../assets/fonts/JournalSansNew-Bold.woff2') format('woff2'),
		url('../assets/fonts/JournalSansNew-Bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

